import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Grid, Button, ButtonGroup } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { HOTEL_LIST_QUERY } from '../gql';
import { EHotelListerStatusType, EHotelWidgetStatusType } from '__generated__/graphql';
import { HotelCopyButton, HotelDeleteButton } from './hotel';
import { CountryName } from 'components/Language';

export default function HotelsList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(HOTEL_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('hotels-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  hotelId: (filter && filter.hotelId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('hotels-list-header-name'),
              i18next.t('hotels-list-header-businesscountry'),
              i18next.t('hotels-list-header-refcode'),
              i18next.t('hotels-list-header-integration'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              i18next.t('hotels-list-header-lister'),
              i18next.t('hotels-list-header-widget'),
              '',
            ]}
            rows={(data?.listHotels || []).map(row => [
              <Link to={`/settings/hotels/${row.id}`}>{row.name}</Link>,
              <CountryName country={row.businessCountry} />,
              row.refCode,
              row.integrations && row.integrations.length > 0 ? (
                <Link to={`/settings/integration/hotels/${row.integrations[0].id}`}>{row.extRefCode}</Link>
              ) : null,
              ...(user.isSingleSpace ? [] : [row.space.name]),
              row.listerStatus === EHotelListerStatusType.READY ? (
                row.listerUrl ? (
                  <Link to={row.listerUrl} target="_blank">
                    {i18next.t('hotels-list-lister-url')}
                  </Link>
                ) : (
                  ''
                )
              ) : row.listerStatus === EHotelListerStatusType.INACTIVE ? <></> : (
                <Alert severity="warning">{i18next.t(`hotel-lister-status-${row.listerStatus}`)}</Alert>
              ),              
              row.widgetStatus === EHotelWidgetStatusType.READY ? (
                row.widgetTestUrl ? (
                  <>
                    <Link to={row.widgetTestUrl} target="_blank">
                      {i18next.t('hotels-list-test-url')}
                    </Link>{' '}
                    (
                    <Link to={`${row.widgetTestUrl}&seminargoNoRooms=Y`} target="_blank">
                      {i18next.t('hotels-list-test-url-no-rooms')}
                    </Link>
                    )
                  </>
                ) : (
                  ''
                )
              ) : row.widgetStatus === EHotelWidgetStatusType.INACTIVE ? <></> : (
                <Alert severity="warning">{i18next.t(`hotel-widget-status-${row.widgetStatus}`)}</Alert>
              ),
              <ButtonGroup>
                <HotelCopyButton id={row.id} spaceId={row.space.id} icon />
                <HotelDeleteButton id={row.id} spaceId={row.space.id} icon />
              </ButtonGroup>,
            ])}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/settings/hotels/_create')}>
              {i18next.t('hotels-list-add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
