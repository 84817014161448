import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, IconButton, Tooltip, Grid, CircularProgress } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpace } from 'helper/security';
import { filterSelector } from 'helper/filter';

import ProductDetailsFlat, {
  ProductDetailsFlatContentSchema,
  ProductDetailsFlatFromTypeToSchema,
  ProductDetailsFlatFromSchemaToType,
} from '../content/products/productdetailsflat_embedded';
import PriceTableView from './pricetable_embedded';
import { AvailabilityEmbeddedTable } from '../availability/availability';

import {
  PRODUCT_VIEW_QUERY,
  UPDATE_PRODUCT_MUTATION,
  CREATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
  COPY_PRODUCT_MUTATION,
  SET_PRODUCTDETAILS_MUTATION,
  REFETCH_PRODUCT_QUERIES,
  EVICT_PRODUCTS_QUERIES,
} from './gql';
import { TAXTYPES_LIST_FULL_QUERY } from '../prices/gql';
import { TaxTypeListOutput, ProductListOutput, EProductPayment, EProductLookupCode, ProductComponentListOutput } from '__generated__/graphql';
import yup from 'validation';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { ISpace } from 'types';
import { TaxComponentSelectionInput } from 'components/tax/TaxComponentSelectionInput';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { isProductionRelease } from 'helper/deployment';

interface ProductProps {
  id: number;
}
interface ProductCreateProps {}
interface ProductFormProps {
  data: ProductListOutput;
  taxTypesData: TaxTypeListOutput[];
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  name: yup.string().required().label(i18next.t('product-name')),
  extRefCode: yup.string().nullable(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  isAddon: yup.boolean().required(),
  isDeduction: yup.boolean().required(),
  sku: yup.string(),
  lookupCode: yup.string().nullable(),
  recurring: yup.string().required(),
  components: yup.array().required().min(1).of(yup.number().required()).label(i18next.t('product-components')),
  content: yup.array().required().of(ProductDetailsFlatContentSchema),
});

function ProductForm(props: ProductFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewQuery] = useLazyQuery(PRODUCT_VIEW_QUERY);
  const [updateMutateFunction] = useMutation(UPDATE_PRODUCT_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_PRODUCT_MUTATION);
  const [setTextMutateFunction] = useMutation(SET_PRODUCTDETAILS_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);
  const canEditText = user.isAdmin;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/products/products/${createdId}`);
  }, [createdId]);

  const [selectedSpace, setSelectedSpace] = useState<ISpace | null>(props.data.space);

  type ProductFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: ProductListOutput): ProductFormType => ({
    ...obj,
    lookupCode: obj.lookupCode || undefined,
    isCreate: props.data.id > 0 ? false : true,
    spaceId: obj.space.id,
    components: obj.components.map(tt => tt.taxType.id),
    content: obj.content.map(ProductDetailsFlatFromTypeToSchema),
    extRefCode: [obj.extRefCode, obj.extPricelistRefCode].filter(r => r).join(' / '),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as ProductListOutput),
  });

  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({
    control,
    name: 'content',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: ProductFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              isAddon: !!values.isAddon,
              isDeduction: !!values.isDeduction,
              recurring: values.recurring as EProductPayment,
              components: values.components.map(c => ({ taxTypeId: c })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
            },
          },
          update: cache => EVICT_PRODUCTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRODUCT_QUERIES(props.data.id, values.sku),
        });
        reset(toFormSchema((res.data!.updateProduct || {}) as ProductListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              isAddon: !!values.isAddon,
              isDeduction: !!values.isDeduction,
              lookupCode: values.lookupCode ? (values.lookupCode as EProductLookupCode) : null,
              recurring: values.recurring as EProductPayment,
              components: values.components.map(c => ({ taxTypeId: c })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType),
            },
          },
          update: cache => EVICT_PRODUCTS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_PRODUCT_QUERIES(),
        });
        reset(toFormSchema((res.data!.createProduct || {}) as ProductListOutput));
        setCreatedId(res.data!.createProduct.id);
      }
      dispatchMessage(dispatch, i18next.t('product-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onSubmitText = async (values: ProductFormType) => {
    if (props.data.id <= 0 || !canEditText) return;
    try {
      const res = await setTextMutateFunction({
        variables: {
          sku: props.data.sku,
          content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
        },
        update: cache => EVICT_PRODUCTS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRODUCT_QUERIES(props.data.id, values.sku),
      });
      const product = await viewQuery({ variables: { id: props.data.id } });
      reset(toFormSchema((product.data!.viewProduct || {}) as ProductListOutput));
      dispatchMessage(dispatch, i18next.t('product-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const initialTabSelected = (location.hash === '#texts' && 2) || (location.hash === '#bundles' && 1) || 0;

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('products-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="name" control={control} label={i18next.t('product-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            onSpaceSelected={space => setSelectedSpace(space)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDropdown
            name="recurring"
            control={control}
            label={i18next.t('product-recurring')}
            options={Object.keys(EProductPayment).map(p => ({
              value: p,
              label: `${i18next.t('enums-EProductPayment-' + p)}`,
            }))}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputText name="sku" control={control} label={i18next.t('product-sku')} disabled />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={3}>
          <FormInputDropdown
            name="lookupCode"
            control={control}
            label={i18next.t('product-lookupcode')}
            options={[
              {
                label: <em>{i18next.t('generic-selection-empty')}</em>,
                value: undefined,
              },
              ...Object.keys(EProductLookupCode).map(p => ({
                value: p,
                label: `${p}`,
              })),
            ]}
            disabled={!canEdit || props.data.id > 0}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormInputText name="extRefCode" control={control} label={i18next.t('product-extrefcode')} disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox name="isAddon" control={control} label={i18next.t('product-isaddon')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputCheckbox name="isDeduction" control={control} label={i18next.t('product-isdeduction')} disabled={!canEdit} />
        </Grid>
        <Grid item xs={12}>
          <TaxComponentSelectionInput
            name="components"
            control={control}
            label={i18next.t('product-components')}
            country={selectedSpace ? selectedSpace.country : null}
            taxTypesData={props.taxTypesData}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            initialSelected={initialTabSelected}
            headers={[
              i18next.t('product-prices-tab'),
              i18next.t('product-bundles-tab'),
              i18next.t('product-text-tab'),
              i18next.t('product-availability-tab'),
            ]}
            icons={[undefined, undefined, validationErrors.content ? <ErrorIcon /> : undefined, undefined]}
            hidden={[false, false, false, isProductionRelease()]}
            disabled={props.data.id > 0 ? [false, false, false, false] : [true, true, true, true]}
            tabs={[
              <PriceTableView prices={props.data.prices} sku={props.data.sku} />,
              <SimpleTable
                headers={[i18next.t('product-bundles-bundle'), i18next.t('product-bundles-included')]}
                rows={
                  props.data
                    ? props.data.bundleItems.map(bundleItem => [
                        <Link to={`/products/bundles/${bundleItem.bundle.id}`}>{bundleItem.bundle.name}</Link>,
                        <Link to={`/products/bundles/${bundleItem.bundle.id}/item/${bundleItem.id}`}>{bundleItem.includedCount}</Link>,
                      ])
                    : []
                }
              />,
              <ProductDetailsFlat
                control={control}
                baseSpaceId={watch('spaceId')}
                fields={contentFields}
                append={contentAppend}
                remove={contentRemove}
                watch={(index, field) => watch(`content.${index}.${field}`)}
                validationErrors={validationErrors}
              />,
              props.data.id > 0 ? <AvailabilityEmbeddedTable productId={props.data.id} /> : null,
            ]}
          />
        </Grid>
        {canEdit && (
          <Grid item xs={12}>
            {showValidationAlert(validationErrors)}
          </Grid>
        )}
        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('product-save')}
            </Button>
          )}
          {props.data.id > 0 && !canEdit && canEditText && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={!isDirty || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitText)();
                }
              }}
            >
              {i18next.t('product-save-text')}
            </Button>
          )}
          {canEdit && props.data.id > 0 && <ProductDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <ProductCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      </Grid>
    </>
  );
}

export default function Product(props: ProductProps) {
  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);
  const productQuery = useQuery(PRODUCT_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = taxTypesQuery.loading || productQuery.loading;
  const error = taxTypesQuery.error || productQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ProductForm
        data={productQuery.data!.viewProduct as ProductListOutput}
        taxTypesData={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
}

export function ProductCreate(props: ProductCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY);

  const loading = taxTypesQuery.loading;
  const error = taxTypesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <ProductForm
        data={{
          id: -1,
          name: '',
          sku: '',
          recurring: EProductPayment.BYGUESTANDDAY,
          isAddon: false,
          isDeduction: false,
          components: [
            {
              id: -1,
              taxType: taxTypesQuery.data!.listTaxTypes.find(t => t.lookupCode === 'DEFAULT2') as TaxTypeListOutput,
            } as ProductComponentListOutput,
          ],
          prices: [],
          bundleItems: [],
          content: [],
          space: initialSpace(user, filter) as any,
        }}
        taxTypesData={taxTypesQuery.data!.listTaxTypes as TaxTypeListOutput[]}
      />
    );
}

interface ProductCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function ProductCopyButton(props: ProductCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isRoot || (user.isAdmin && user.space);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [spaceId, setSpaceId] = useState<number>(props.spaceId);

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_PRODUCT_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id, spaceId },
        update: cache => EVICT_PRODUCTS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_PRODUCT_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('product-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <>
        <IconButton disabled={!canEdit || copyMutateLoading} onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}>
          <Tooltip title={i18next.t('product-copy')}>
            <ContentCopyIcon />
          </Tooltip>
        </IconButton>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('product-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('product-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          disabled={!canEdit || copyMutateLoading}
          startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
          onClick={() => (user.isSingleSpace ? __do() : setDialogOpen(true))}
        >
          {i18next.t('product-copy')}
        </Button>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('product-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('product-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  }
}

interface ProductDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function ProductDeleteButton(props: ProductDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_PRODUCT_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('product-confirm-delete')}
      confirmationTitle={i18next.t('product-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_PRODUCTS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_PRODUCT_QUERIES(),
          });
          navigate('/products/products');
          dispatchMessage(dispatch, i18next.t('product-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('product-delete')}
    </ConfirmationButton>
  );
}
