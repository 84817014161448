import React from 'react';
import { ECountryCode, ELanguageCode } from "__generated__/graphql";
import { allCountriesByCode, allLanguagesByCode } from "languages";

interface CountryProps {
  country?: ECountryCode | null
}

export function CountryName(props: CountryProps) {
  return <>{props.country ? (allCountriesByCode[props.country] || { label: null }).label : null}</>
}

interface LanguageProps {
  language?: ELanguageCode | null
}

export function LanguageName(props: LanguageProps) {
  return <>{props.language ? (allLanguagesByCode[props.language] || { label: null }).label : null}</>
}