import React, { useMemo } from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { FormInputDropdown } from '../form/FormInputDropdown';
import { FormSelectOption } from '../form/FormInputProps';
import { userSelector } from 'helper/security';
import { IUser, ISpace, IBoundToSpace } from 'types';

import { unicodeIndent } from './SpaceSelectionInput';
import { FormInputMultiCheckbox } from 'components/form/FormInputMultiCheckbox';

interface ListSelectionProps<TList extends IBoundToSpace> {
  list: TList[];
  mapId?: (e: TList) => any;
  mapElement?: (e: TList) => React.ReactNode;
  name: string;
  label?: string | null;
  emptyLabel?: React.ReactNode;
  emptyValue?: any;
  control: any;
  [key: string]: any;
}

export const buildGroupedSpaceSelectionOptions = function <TList extends IBoundToSpace>(
  user: IUser,
  list: TList[],
  emptyLabel?: React.ReactNode,
  emptyValue?: any,
  mapId?: (e: TList) => any,
  mapElement?: (e: TList) => React.ReactNode,
  skipIndent?: boolean,
) {
  const optionList = user.isSingleSpace ? list.filter(l => l.space?.id === user.space?.id) : list;

  const options = [] as FormSelectOption[];
  if (emptyLabel) {
    options.push({
      label: <em>{emptyLabel}</em>,
      value: emptyValue,
    });
  }

  const _addSpace = (space: ISpace, level: number = 0) => {
    const spaceObjs = optionList.filter(l => l.space && l.space.id == space.id);
    if (spaceObjs.length === 0 && (!space.children || space.children.length === 0)) return [];

    const childOptions = [] as FormSelectOption[];
    if (spaceObjs.length > 0) {
      for (const spaceObj of spaceObjs) {
        childOptions.push({
          value: mapId ? mapId(spaceObj) : (spaceObj as any).id,
          label: (
            <>
              {skipIndent ? '' : unicodeIndent(level) + ' '}
              {mapElement
                ? mapElement(spaceObj)
                : `${(spaceObj as any).name}${(spaceObj as any).extRefCode ? ` (${(spaceObj as any).extRefCode})` : ''}`}
            </>
          ),
        });
      }
    }
    if (space.children && space.children.length > 0) {
      const children = user.spaces.filter(fs => (space.children || []).findIndex(cc => fs.id == cc.id) >= 0);
      for (const child of children) {
        childOptions.push(..._addSpace(child, level + 1));
      }
    }
    if (childOptions.length > 0) {
      return [
        {
          divider: true,
          label: (
            <em>
              {skipIndent ? '' : unicodeIndent(level) + ' '}
              {space.name}
            </em>
          ),
        },
        ...childOptions,
      ];
    }
    return [];
  };
  const rootSpaces = user.spaces.filter(s => !s.parent);
  for (const rootSpace of rootSpaces) {
    options.push(..._addSpace(rootSpace, 0));
  }

  const spaceIds = _.uniq(optionList.map(l => (l.space ? l.space.id : 0)));
  if (spaceIds.length > 1) {
    return options;
  } else {
    return options.filter(o => !o.divider);
  }
};

export const GroupedSpaceSelectionInput = function <TList extends IBoundToSpace>(props: ListSelectionProps<TList>) {
  const { list, mapElement, mapId, name, label, emptyLabel, emptyValue, control, ...rest } = props;
  const user = userSelector()!;

  const options = useMemo(
    () =>
      buildGroupedSpaceSelectionOptions(
        user,
        list,
        emptyLabel || i18next.t('generic-selection-empty'),
        emptyValue !== undefined ? emptyValue : 0,
        mapId,
        mapElement,
      ),
    [user, list],
  );

  return <FormInputDropdown name={name} control={control} label={label} options={options} {...rest} />;
};

export const GroupedSpaceMultiCheckboxInput = function <TList extends IBoundToSpace>(props: ListSelectionProps<TList>) {
  const { list, mapElement, mapId, name, label, emptyLabel, emptyValue, control, ...rest } = props;
  const user = userSelector()!;

  const options = useMemo(
    () =>
      buildGroupedSpaceSelectionOptions(
        user,
        list,
        undefined,
        undefined,
        mapId,
        mapElement,
        true,
      ),
    [user, list],
  );
  return <FormInputMultiCheckbox name={name} control={control} label={label} options={options} {...rest} />;
};
