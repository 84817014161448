import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Grid, CircularProgress, Button } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import ArticleIcon from '@mui/icons-material/Article';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputCountryDropdown, FormInputDropdown } from 'components/form/FormInputDropdown';
import { buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import {
  SPACE_VIEW_QUERY,
  SEMPLANS_LIST_QUERY,
  UPDATE_SPACE_MUTATION,
  IMPORT_DEFAULTOFFERTEMPLATES_MUTATION,
  IMPORT_DEFAULTEMAILTEMPLATES_MUTATION,
  REFETCH_SPACES_QUERIES,
  EVICT_SPACES_QUERIES,
} from '../gql';

import {
  REFETCH_OFFERTEMPLATES_QUERIES,
  EVICT_OFFERTEMPLATES_QUERIES,
  REFETCH_EMAILTEMPLATES_QUERIES,
  EVICT_EMAILTEMPLATES_QUERIES,
} from '../../content/gql';

import { Space, SEMPlan, ELicenseSEMPlan, ECountryCode } from '__generated__/graphql';
import { userSelector, canEditAdminSpaceId } from 'helper/security';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface SpaceProps {
  id: number;
}
interface SpaceFormProps {
  data: Space;
  plans: SEMPlan[];
}

const validationSchema = yup.object().shape({
  name: yup.string().required().label(i18next.t('space-name')),
  country: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('space-country')),
  parentId: yup.number().nullable().label(i18next.t('space-parent')),
  plan: yup.string().nullable(),
});

function SpaceForm(props: SpaceFormProps) {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminSpaceId(user, props.data.id);

  const [updateMutateFunction] = useMutation(UPDATE_SPACE_MUTATION);
  const [importOfferTemplatesMutateFunction, { loading: importOfferTemplatesLoading }] = useMutation(IMPORT_DEFAULTOFFERTEMPLATES_MUTATION);
  const [importEmailTemplatesMutateFunction, { loading: importEmailTemplatesLoading }] = useMutation(IMPORT_DEFAULTEMAILTEMPLATES_MUTATION);

  type SpaceFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: Space): SpaceFormType => ({
    ...obj,
    parentId: obj.parent ? obj.parent.id : 0,
    plan: obj.activeSEMPlan ? obj.activeSEMPlan.semPlan.plan : null,
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client },
    defaultValues: toFormSchema((props.data || {}) as Space),
  });

  const onSubmit = async (values: SpaceFormType) => {
    try {
      const res = await updateMutateFunction({
        variables: {
          id: props.data.id,
          data: {
            name: values.name,
            country: values.country,
            parentId: values.parentId || null,
            semPlan: user.isRoot && values.plan ? (values.plan as ELicenseSEMPlan) : null,
          },
        },
        update: cache => EVICT_SPACES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_SPACES_QUERIES(props.data.id),
      });
      reset(toFormSchema((res.data!.updateSpace || {}) as Space));
      dispatchMessage(dispatch, i18next.t('space-updated'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('spaces-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={4}>
          <FormInputText name="name" control={control} label={i18next.t('space-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputDropdown
            name="parentId"
            control={control}
            disabled={!canEdit}
            label={i18next.t('space-parentspace')}
            options={[
              {
                label: <em>{i18next.t('generic-selection-empty')}</em>,
                value: 0,
              },
              ...buildSpaceSelectionOptions(user, true).filter(e => e.value !== props.data.id),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputDropdown
            name="plan"
            control={control}
            label={i18next.t('space-license')}
            options={props.plans.map(p => ({
              value: p.plan,
              label: p.label,
            }))}
            disabled={!user.isRoot}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputCountryDropdown
            name="country"
            control={control}
            label={i18next.t('space-country')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {canEdit && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              confirmationQuestion={i18next.t('space-confirm-update')}
              confirmationTitle={i18next.t('space-confirm-update-title')}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('space-update')}
            </ConfirmationButton>
          )}
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={importOfferTemplatesLoading ? <CircularProgress size={24} /> : <ArticleIcon />}
              disabled={importOfferTemplatesLoading}
              onClick={async () => {
                try {
                  const res = await importOfferTemplatesMutateFunction({
                    variables: {
                      targetSpaceId: props.data.id,
                    },
                    update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
                    awaitRefetchQueries: true,
                    refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(),
                  });
                  dispatchMessage(dispatch, i18next.t('space-offertemplatesimported'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('space-importoffertemplates')}
            </Button>
          )}
          {canEdit && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={importEmailTemplatesLoading ? <CircularProgress size={24} /> : <ArticleIcon />}
              disabled={importEmailTemplatesLoading}
              confirmationQuestion={i18next.t('space-confirm-importemailtemplates')}
              confirmationTitle={i18next.t('space-confirm-importemailtemplates-title')}
              onConfirm={async () => {
                try {
                  const res = await importEmailTemplatesMutateFunction({
                    variables: {
                      targetSpaceId: props.data.id,
                    },
                    update: cache => EVICT_EMAILTEMPLATES_QUERIES(cache),
                    awaitRefetchQueries: true,
                    refetchQueries: REFETCH_EMAILTEMPLATES_QUERIES(),
                  });
                  dispatchMessage(dispatch, i18next.t('space-emailtemplatesimported'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('space-importemailtemplates')}
            </ConfirmationButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default function SpaceUpdate(props: SpaceProps) {
  const userQuery = useQuery(SPACE_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const plansQuery = useQuery(SEMPLANS_LIST_QUERY);

  const loading = userQuery.loading || plansQuery.loading;
  const error = userQuery.error || plansQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <SpaceForm data={userQuery.data!.viewSpace as Space} plans={plansQuery.data!.listSEMPlans as SEMPlan[]} />;
}
