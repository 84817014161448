import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

interface TabsPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

interface TabsProps {
  headers: string[];
  icons?: (React.ReactElement | undefined)[];
  disabled?: boolean[];
  hidden?: boolean[];
  tabs: React.ReactNode[];
  initialSelected?: number | null;
}
interface TabsControlledProps {
  headers: string[];
  icons?: (React.ReactElement | undefined)[];
  disabled?: boolean[];
  hidden?: boolean[];
  tabs: React.ReactNode[];
  selected: number;
  handleChange: (selected: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabsPanel(props: TabsPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function CustomTabsControlled(props: TabsControlledProps) {
  const { headers, disabled, icons, tabs, selected, hidden, handleChange } = props;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selected} onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(newValue)}>
          {headers.map((h, index) =>
            (!hidden || !hidden[index]) &&
              <Tab
                key={index}
                value={index}
                label={h}
                {...a11yProps(index)}
                disabled={disabled && disabled[index]}
                icon={icons && icons[index]}
                iconPosition="start"
              />
          )}
        </Tabs>
      </Box>
      {tabs.map((c, index) =>
        disabled && disabled[index] ? (
          <React.Fragment key={index} />
        ) : (
          <CustomTabsPanel key={index} value={selected} index={index}>
            {c}
          </CustomTabsPanel>
        ),
      )}
    </>
  );
}

export default function CustomTabs(props: TabsProps) {
  const { headers, disabled, icons, tabs, hidden, initialSelected } = props;

  const [value, setValue] = React.useState(initialSelected || 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {headers.map((h, index) =>
            (!hidden || !hidden[index]) &&
              <Tab
                key={index}
                value={index}
                label={h}
                {...a11yProps(index)}
                disabled={disabled && disabled[index]}
                icon={icons && icons[index]}
                iconPosition="start"
              />
          )}
        </Tabs>
      </Box>
      {tabs.map((c, index) =>
        disabled && disabled[index] ? (
          <React.Fragment key={index} />
        ) : (
          <CustomTabsPanel key={index} value={value} index={index}>
            {c}
          </CustomTabsPanel>
        ),
      )}
    </>
  );
}
