import React from 'react';
import { MenuItem, Select, IconButton, CircularProgress, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { hintValidationAlert } from 'helper/form';
import yup from 'validation';

import SimpleTable from 'components/table/SimpleTable';
import { FormInputText } from 'components/form/FormInputText';
import { FormInputLanguageDropdown } from 'components/form/FormInputDropdown';
import { HotelSelectionInput } from 'components/security/HotelSelectionInput';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import { filterSelector } from 'helper/filter';
import { userSelector, canEditAdminSpaceId, hasAnyAdminSpace } from 'helper/security';

import { HOTEL_LIST_QUERY } from '../../settings/gql';
import { ContentFlatFragment, HotelListOutput, EContentProductDetailPosition, ELanguageCode } from '__generated__/graphql';
import { RedirectError } from 'pages/error';

interface TIter {
  map: (fn: (content: ContentFlatFragment & { id: string }, index: number) => any) => any;
}

interface ProductDetailsFlatProps {
  control: any;
  validationErrors: any;
  emptyHotelId?: number;
  emptyLanguage?: ELanguageCode;
  baseSpaceId?: number;
  fields: TIter;
  append: (item: ContentFlatFragment) => void;
  remove: (index: number) => void;
  watch: (index: number, field: keyof ProductDetailsFlatContentSchemaType) => any;
}

interface ProductDetailsFlatFormProps extends ProductDetailsFlatProps {
  hotels: HotelListOutput[];
}

export const ProductDetailsFlatContentSchema = yup.object().shape({
  position: yup.mixed<EContentProductDetailPosition>().required().oneOf(Object.values(EContentProductDetailPosition)),
  hotelId: yup.number().nullable(),
  language: yup.mixed<ELanguageCode>().nullable().oneOf(Object.values(ELanguageCode)),
  text: yup.string().required().label(i18next.t('contentproduct-text-text')),
  spaceId: yup.number().required().label(i18next.t('field-space')),
});
type ProductDetailsFlatContentSchemaType = yup.InferType<typeof ProductDetailsFlatContentSchema>;

export const ProductDetailsFlatFromTypeToSchema = (c: ContentFlatFragment) => ({
  position: c.position,
  hotelId: c.hotelId || 0,
  language: c.language || null,
  text: c.text,
  spaceId: c.spaceId,
});
export const ProductDetailsFlatFromSchemaToType = (c: ProductDetailsFlatContentSchemaType) => ({
  position: c.position,
  hotelId: c.hotelId && c.hotelId > 0 ? c.hotelId! : null,
  language: c.language || null,
  text: c.text,
  spaceId: c.spaceId,
});

export function ProductDetailsFlatForm(props: ProductDetailsFlatFormProps) {
  const user = userSelector()!;
  const filter = filterSelector();

  return (
    <>
      <SimpleTable
        headers={[
          '',
          i18next.t('content-product-header-position'),
          i18next.t('content-product-header-text'),
          i18next.t('content-product-header-hotel'),
          ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
          i18next.t('content-product-header-language'),
          '',
        ]}
        rows={props.fields
          .map((field, index) =>
            !filter || !filter.hotelId || !props.watch(index, 'hotelId') || props.watch(index, 'hotelId') === filter.hotelId
              ? [
                  hintValidationAlert((props.validationErrors.content || [])[index]),
                  <Tooltip key={`${(field as any).id}.position`} title={i18next.t(`enums-EContentProductDetailPosition-${field.position}-tooltip`)}>
                    <span>{i18next.t(`enums-EContentProductDetailPosition-${field.position}`)}</span>
                  </Tooltip>,
                  field.position === EContentProductDetailPosition.DETAILS ? (
                    <FormInputText
                      key={`${field.id}.text`}
                      name={`content.${index}.text`}
                      control={props.control}
                      label={i18next.t('contentproduct-text-text')}
                      textFieldProps={{ fullWidth: true, multiline: true, rows: 5 }}
                      required
                      disabled={!canEditAdminSpaceId(user, field.spaceId)}
                    />
                  ) : (
                    <FormInputText
                      key={`${field.id}.text`}
                      name={`content.${index}.text`}
                      control={props.control}
                      label={i18next.t('contentproduct-text-text')}
                      textFieldProps={{ fullWidth: true }}
                      required
                      disabled={!canEditAdminSpaceId(user, field.spaceId)}
                    />
                  ),
                  <HotelSelectionInput
                    key={`${field.id}.hotelId`}
                    label={i18next.t('contentproduct-hotel')}
                    name={`content.${index}.hotelId`}
                    control={props.control}
                    disabled={!canEditAdminSpaceId(user, field.spaceId)}
                    hotels={props.hotels.filter(h => h.id === props.watch(index, 'hotelId') || h.space.id === props.watch(index, 'spaceId'))}
                  />,
                  ...(user.isSingleSpace
                    ? []
                    : [
                        <SpaceSelectionInput
                          key={`${field.id}.spaceId`}
                          checkAdmin
                          baseEnabledSpaceId={props.baseSpaceId}
                          name={`content.${index}.spaceId`}
                          control={props.control}
                          disabled={!canEditAdminSpaceId(user, field.spaceId)}
                          required
                        />,
                      ]),
                  <FormInputLanguageDropdown
                    key={`${field.id}.language`}
                    name={`content.${index}.language`}
                    control={props.control}
                    label={i18next.t('contentproduct-text-language')}
                    disabled={!canEditAdminSpaceId(user, field.spaceId)}
                  />,
                  canEditAdminSpaceId(user, field.spaceId) && (
                    <IconButton onClick={() => props.remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  ),
                ]
              : null,
          )
          .filter((h: any) => h)}
      />
      {hasAnyAdminSpace(user) && (
        <Select
          value={''}
          displayEmpty
          onChange={event =>
            event.target.value &&
            props.append({
              position: event.target.value as EContentProductDetailPosition,
              hotelId: (filter && filter.hotelId) || props.emptyHotelId || 0,
              language: props.emptyLanguage || null,
              text: '',
              spaceId: (filter && filter.spaceId) || (user.space ? user.space.id : user.spaces.length > 0 ? user.spaces[0].id : 0),
            })
          }
        >
          <MenuItem value={''}>
            <em>{i18next.t('content-product-add')}</em>
          </MenuItem>
          {Object.keys(EContentProductDetailPosition)
            .filter(k => k != EContentProductDetailPosition.GROUP)
            .map((p, index) => (
              <MenuItem key={index} value={p}>
                {i18next.t('enums-EContentProductDetailPosition-' + p)}
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
}

export default function ProductDetailsFlat(props: ProductDetailsFlatProps) {
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);

  const loading = hotelsQuery.loading;
  const error = hotelsQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <ProductDetailsFlatForm {...props} hotels={hotelsQuery.data!.listHotels as HotelListOutput[]} />;
}
