import _ from 'lodash';
import { useState, useEffect } from 'react';
import { IBoundToSpace } from 'types';
import { userSelector } from './security';
import i18next from 'i18next';

type TStackElement = (IBoundToSpace & { id?: number | null, name?: string | null, sku?: string | null, refCode?: string | null }) | string | null

export const formatDocumentTitle = (stack: TStackElement[]) => {
  const user = userSelector()

  return stack.reduce((agg, element) => {
    if (element === null) return [...agg]
    if (_.isString(element)) return [...agg, element]

    return [
      ...agg,
      ...(user?.isSingleSpace ? [] : element.space && element.space.name ? [element.space.name] : []),
      ...(element.id === undefined || element.id === null || element.id < 0 ? [i18next.t('generic-new')] : element.name ? [element.name] : element.sku ? [element.sku] : element.refCode ? [element.refCode] : [])
    ]
  }, [] as string[]).join(' / ')
}

export const useDocumentTitle = () => {
  const [title, setTitle] = useState(document.title);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      if (mutations[0].target.textContent) {
        setTitle(mutations[0].target.textContent)
      }
    })
    observer.observe(document.querySelector('title')!, {
      subtree: true,
      characterData: true,
      childList: true,
    })
    return () => observer.disconnect()
  }, [])

  return title;
};