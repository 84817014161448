import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, IconButton, Button, Grid, CircularProgress } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputPercentage } from 'components/form/FormInputPercentage';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputCountryDropdown } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';

import {
  TAXTYPE_VIEW_QUERY,
  UPDATE_TAXTYPE_MUTATION,
  CREATE_TAXTYPE_MUTATION,
  DELETE_TAXTYPE_MUTATION,
  REFETCH_TAXTYPES_QUERIES,
  EVICT_TAXTYPES_QUERIES,
} from './gql';
import { ECountryCode, TaxTypeListOutput } from '__generated__/graphql';

import { userSelector, canEditAdminRecord, initialSpaceId } from 'helper/security';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface TaxTypeProps {
  id: number;
}
interface TaxTypeCreateProps {}
interface TaxTypeFormProps {
  data: TaxTypeListOutput;
}

const rateSchema = yup.object().shape({
  id: yup.number(),
  rate: yup.number().min(0).max(100).required().label(i18next.t('taxtype-rate-rate')),
  name: yup.string().required().label(i18next.t('taxtype-rate-name')),
  country: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).nullable(),
  isReverseCharge: yup.boolean().required(),
  validFrom: yup.date().nullable(),
  validTo: yup.date().nullable(),
});

const validationSchema = yup
  .object()
  .shape({
    spaceId: yup.number().nullable(),
    name: yup.string().required().label(i18next.t('taxtype-name')),
    sequence: yup.number().required().label(i18next.t('taxtype-sequence')),
    rates: yup.array().required().min(1).of(rateSchema),
  })
  .test('taxtype-rates', `${i18next.t('taxtype-rates')}`, value => value.rates.length > 0);

function TaxTypeForm(props: TaxTypeFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateMutateFunction] = useMutation(UPDATE_TAXTYPE_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_TAXTYPE_MUTATION);
  const [deleteMutateFunction] = useMutation(DELETE_TAXTYPE_MUTATION);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/pricelists/taxtypes/${createdId}`);
  }, [createdId]);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  type TaxTypeFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: TaxTypeListOutput): TaxTypeFormType => ({
    spaceId: obj.space?.id || null,
    name: obj.name,
    sequence: obj.sequence,
    rates: obj.rates.map(rate => ({
      ...rate,
      country: rate.country,
      rate: Math.round(rate.rate * 10000) / 100,
      validFrom: rate.validFrom ? new Date(rate.validFrom) : null,
      validTo: rate.validTo ? new Date(rate.validTo) : null,
    })),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as TaxTypeListOutput),
  });

  const {
    fields: ratesFields,
    append: ratesAppend,
    remove: ratesRemove,
  } = useFieldArray({
    control,
    name: 'rates',
  });
  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: TaxTypeFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              sequence: values.sequence,
              rates: values.rates.map(c => ({
                id: c.id,
                rate: c.rate / 100,
                name: c.name,
                isReverseCharge: c.isReverseCharge,
                country: c.country,
                validFrom: c.validFrom,
                validTo: c.validTo,
              })),
            },
          },
          update: cache => EVICT_TAXTYPES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_TAXTYPES_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateTaxType || {}) as TaxTypeListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId !== null ? values.spaceId : null,
            data: {
              name: values.name,
              sequence: values.sequence,
              rates: values.rates.map(c => ({
                rate: c.rate / 100,
                name: c.name,
                isReverseCharge: c.isReverseCharge,
                country: c.country,
                validFrom: c.validFrom,
                validTo: c.validTo,
              })),
            },
          },
          update: cache => EVICT_TAXTYPES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_TAXTYPES_QUERIES(),
        });
        reset(toFormSchema((res.data!.createTaxType || {}) as TaxTypeListOutput));
        setCreatedId(res.data!.createTaxType.id);
      }
      dispatchMessage(dispatch, i18next.t('taxtype-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('taxtypes-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="name" control={control} label={i18next.t('taxtype-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            allowEmptyForRoot
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputNumber name="sequence" label={i18next.t('taxtype-sequence')} control={control} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12}>
          <CustomTabs
            headers={[i18next.t('taxtype-rates-tab')]}
            tabs={[
              <>
                <SimpleTable
                  rows={ratesFields.map((field, index) => [
                    <FormInputPercentage
                      key={`${field.id}.rate`}
                      name={`rates.${index}.rate`}
                      control={control}
                      label={i18next.t('taxtype-rate-rate')}
                      required
                      disabled={!canEdit}
                    />,
                    <FormInputText
                      key={`${field.id}.name`}
                      name={`rates.${index}.name`}
                      control={control}
                      label={i18next.t('taxtype-rate-name')}
                      required
                      disabled={!canEdit}
                    />,
                    <FormInputCountryDropdown
                      key={`${field.id}.country`}
                      name={`rates.${index}.country`}
                      control={control}
                      label={i18next.t('taxtype-rate-country')}
                      disabled={!canEdit}
                    />,
                    <FormInputDate
                      key={`${field.id}.validFrom`}
                      name={`rates.${index}.validFrom`}
                      control={control}
                      label={i18next.t('taxtype-rate-validfrom')}
                      textFieldProps={{ fullWidth: false }}
                      disabled={!canEdit}
                    />,
                    <FormInputDate
                      key={`${field.id}.validTo`}
                      name={`rates.${index}.validTo`}
                      control={control}
                      label={i18next.t('taxtype-rate-validto')}
                      textFieldProps={{ fullWidth: false }}
                      disabled={!canEdit}
                    />,
                    <FormInputCheckbox
                      key={`${field.id}.isReverseCharge`}
                      name={`rates.${index}.isReverseCharge`}
                      control={control}
                      label={i18next.t('taxtype-rate-isreversecharge')}
                      disabled={!canEdit}
                    />,
                    canEdit ? (
                      <IconButton onClick={() => ratesRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    ) : null,
                  ])}
                />
                {canEdit && (
                  <Button
                    sx={{ marginRight: 2 }}
                    startIcon={<AddIcon />}
                    onClick={() =>
                      ratesAppend({
                        rate: 0,
                        name: '',
                        country: null,
                        isReverseCharge: false,
                      })
                    }
                  >
                    {i18next.t('taxtype-rate-add')}
                  </Button>
                )}
              </>,
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('taxtype-save')}
            </Button>
          )}
          {canEdit && props.data.id > 0 && (
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              confirmationQuestion={i18next.t('taxtype-confirm-delete')}
              confirmationTitle={i18next.t('taxtype-confirm-delete-title')}
              icon={false}
              onConfirm={async () => {
                try {
                  const res = await deleteMutateFunction({
                    variables: {
                      id: props.data.id,
                    },
                    update: cache => EVICT_TAXTYPES_QUERIES(cache),
                    awaitRefetchQueries: true,
                    refetchQueries: REFETCH_TAXTYPES_QUERIES(),
                  });
                  navigate('/pricelists/taxtypes');
                  dispatchMessage(dispatch, i18next.t('taxtype-deleted'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('taxtype-delete')}
            </ConfirmationButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default function TaxType(props: TaxTypeProps) {
  const taxTypeQuery = useQuery(TAXTYPE_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = taxTypeQuery.loading;
  const error = taxTypeQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <TaxTypeForm data={taxTypeQuery.data!.viewTaxType as TaxTypeListOutput} />;
}

export function TaxTypeCreate(props: TaxTypeCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <TaxTypeForm
      data={{
        id: -1,
        space: { id: initialSpaceId(user, filter) } as any,
        sequence: 0,
        name: '',
        rates: [{ id: -1, name: '', rate: 0, isReverseCharge: false }],
      }}
    />
  );
}
