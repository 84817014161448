import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';
import { REFETCH_CONTENTPRODUCTS_QUERIES, EVICT_CONTENTPRODUCTS_QUERIES } from '../content/gql';
import { REFETCH_HOTELS_QUERIES, EVICT_HOTELS_QUERIES } from '../settings/gql';

export const CONTENTFLAT_VIEW_FRAGMENT = gql(`
  fragment ContentFlat on ContentProductDetailFlatOutput {
    position
    hotelId
    language
    text
    spaceId
  }
`);

export const PRODUCTS_LIST_SHORT_FRAGMENT = gql(`
  fragment ProductListShort on ProductListOutput {
    id
    sku
    name
    extRefCode
    extPricelistRefCode
    recurring
    lookupCode
    isAddon
    isDeduction
    space { ...SpaceShort }
  }
`);

export const PRODUCTS_LIST_COMPONENTS_FRAGMENT = gql(`
  fragment ProductListComponents on ProductListOutput {
    ...ProductListShort
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }
  }
`);

export const PRODUCTS_LIST_VIEW_FRAGMENT = gql(`
  fragment ProductListView on ProductListOutput {
    id
    name
    extRefCode
    extPricelistRefCode
    sku
    isAddon
    isDeduction
    lookupCode
    recurring
    space { ...SpaceShort }
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }
    bundleItems {
      id
      includedCount
      isOptional
      bundle {
        id
        name
      }
    }
    prices {
      ...ProductPriceShort
    }
    content {
      ...ContentFlat
    }
  }
`);

export const SET_PRODUCTDETAILS_MUTATION = gql(`
  mutation SetProductDetailContent($sku: String!, $content: [ContentProductDetailFlatInput!]!) {
    setProductDetailContent(sku: $sku, content: $content)
  }
`);

export const PRODUCTS_LIST_QUERY = gql(`
  query ListProducts($spaceId: Int, $where: ProductWhereInput, $skip: Int, $take: Int) {
    listProducts(spaceId: $spaceId, where: $where, skip: $skip, take: $take) {
      ...ProductListShort
    }
  }
`);

export const PRODUCTS_COMPONENTS_QUERY = gql(`
  query ListProductsComponents($spaceId: Int, $where: ProductWhereInput, $skip: Int, $take: Int) {
    listProducts(spaceId: $spaceId, where: $where, skip: $skip, take: $take) {
      ...ProductListComponents
    }
  }
`);

export const PRODUCT_VIEW_QUERY = gql(`
  query ViewProduct($id: Int!) {
    viewProduct(id: $id) {
      ...ProductListView
    }
  }
`);

export const CREATE_PRODUCT_MUTATION = gql(`
  mutation CreateProduct($spaceId: Int!, $data: ProductCreateInput!) {
    createProduct(spaceId: $spaceId, data: $data) {
      ...ProductListView
    }
  }
`);

export const UPDATE_PRODUCT_MUTATION = gql(`
  mutation UpdateProduct($id: Int!, $data: ProductInput!) {
    updateProduct(id: $id, data: $data) {
      ...ProductListView
    }
  }
`);

export const DELETE_PRODUCT_MUTATION = gql(`
  mutation DeleteProduct($id: Int!) {
    deleteProduct(id: $id)
  }
`);

export const COPY_PRODUCT_MUTATION = gql(`
  mutation CopyProduct($id: Int!, $spaceId: Int!) {
    copyProduct(id: $id, spaceId: $spaceId) {
      ...ProductListView
    }
  }
`);

export const EVICT_PRODUCTS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listProducts' });
  cache.evict({ fieldName: 'viewProduct' });
  EVICT_CONTENTPRODUCTS_QUERIES(cache);
  cache.gc();
};

export const REFETCH_PRODUCT_QUERIES = (productId?: number | undefined, sku?: string | undefined) => [
  ...(productId
    ? [
        {
          query: PRODUCT_VIEW_QUERY,
          variables: { id: productId },
        },
      ]
    : []),
];

export const PRODUCTBUNDLES_LIST_SHORT_FRAGMENT = gql(`
  fragment ProductBundleListShort on ProductBundleListOutput {
    id
    sku
    name
    extRefCode
    extPricelistRefCode
    recurring
    lookupCode
    space { ...SpaceShort }
  }
`);

export const PRODUCTBUNDLES_LIST_COMPONENTS_FRAGMENT = gql(`
  fragment ProductBundleListComponents on ProductBundleListOutput {
    ...ProductBundleListShort
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }
    bundleItems {
      id
      includedCount
      isOptional
      product {
        id
        name
        sku
        isDeduction
      }
    }
  }
`);

export const PRODUCTBUNDLES_LIST_VIEW_FRAGMENT = gql(`
  fragment ProductBundleListView on ProductBundleListOutput {
    id
    name
    extRefCode
    extPricelistRefCode
    sku
    recurring
    lookupCode
    space { ...SpaceShort }
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }    
    bundleItems {
      id
      includedCount
      isOptional
      product {
        id
        name
        sku
        isDeduction
      }
    }
    prices {
      ...ProductPriceShort
    }
    content {
      ...ContentFlat
    }    
  }
`);

export const PRODUCTBUNDLES_LIST_QUERY = gql(`
  query ListProductBundles($spaceId: Int, $where: ProductBundleWhereInput, $skip: Int, $take: Int) {
    listProductBundles(spaceId: $spaceId, where: $where, skip: $skip, take: $take) {
      ...ProductBundleListShort
    }
  }
`);

export const PRODUCTBUNDLES_COMPONENTS_QUERY = gql(`
  query ListProductBundlesComponents($where: ProductBundleWhereInput, $skip: Int, $take: Int) {
    listProductBundles(where: $where, skip: $skip, take: $take) {
      ...ProductBundleListComponents
    }
  }
`);

export const PRODUCTBUNDLE_VIEW_QUERY = gql(`
  query ViewProductBundle($id: Int!) {
    viewProductBundle(id: $id) {
      ...ProductBundleListView
    }
  }
`);

export const CREATE_PRODUCTBUNDLE_MUTATION = gql(`
  mutation CreateProductBundle($spaceId: Int!, $data: ProductBundleCreateInput!) {
    createProductBundle(spaceId: $spaceId, data: $data) {
      ...ProductBundleListView
    }
  }
`);

export const UPDATE_PRODUCTBUNDLE_MUTATION = gql(`
  mutation UpdateProductBundle($id: Int!, $data: ProductBundleInput!) {
    updateProductBundle(id: $id, data: $data) {
      ...ProductBundleListView
    }
  }
`);

export const DELETE_PRODUCTBUNDLE_MUTATION = gql(`
  mutation DeleteProductBundle($id: Int!) {
    deleteProductBundle(id: $id)
  }
`);

export const COPY_PRODUCTBUNDLE_MUTATION = gql(`
  mutation CopyProductBundle($id: Int!, $spaceId: Int!) {
    copyProductBundle(id: $id, spaceId: $spaceId) {
      ...ProductBundleListView
    }
  }
`);

export const EVICT_PRODUCTBUNDLES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listProductBundles' });
  cache.evict({ fieldName: 'viewProductBundle' });
  EVICT_CONTENTPRODUCTS_QUERIES(cache);
  cache.gc();
};

export const REFETCH_PRODUCTBUNDLES_QUERIES = (bundleId?: number | undefined, sku?: string | undefined) => [
  ...(bundleId
    ? [
        {
          query: PRODUCTBUNDLE_VIEW_QUERY,
          variables: { id: bundleId },
        },
      ]
    : []),
];

export const FACILITIES_LIST_SHORT_FRAGMENT = gql(`
  fragment FacilityListShort on FacilityListOutput {
    id
    sku
    name
    extRefCode
    extPricelistRefCode
    recurring
    lookupCode
    isAddon
    space { ...SpaceShort }
  }
`);

export const FACILITIES_LIST_COMPONENTS_FRAGMENT = gql(`
  fragment FacilityListComponents on FacilityListOutput {
    ...FacilityListShort
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }
  }
`);

export const FACILITIES_LIST_VIEW_FRAGMENT = gql(`
  fragment FacilityListView on FacilityListOutput {
    id
    name
    extRefCode
    extPricelistRefCode
    sku
    lookupCode
    recurring
    isAddon
    space { ...SpaceShort }
    components {
      id
      taxType {
        id
        name
        lookupCode
      }
    }
    prices {
      ...ProductPriceShort
    }
    content {
      ...ContentFlat
    }    
  }
`);

export const FACILITIES_LIST_QUERY = gql(`
  query ListFacilities($spaceId: Int, $where: FacilityWhereInput, $skip: Int, $take: Int) {
    listFacilities(spaceId: $spaceId, where: $where, skip: $skip, take: $take) {
      ...FacilityListShort
    }
  }
`);

export const FACILITIES_COMPONENTS_QUERY = gql(`
  query ListFacilitiesComponents($where: FacilityWhereInput, $skip: Int, $take: Int) {
    listFacilities(where: $where, skip: $skip, take: $take) {
      ...FacilityListComponents
    }
  }
`);

export const FACILITY_VIEW_QUERY = gql(`
  query ViewFacility($id: Int!) {
    viewFacility(id: $id) {
      ...FacilityListView
    }
  }
`);

export const CREATE_FACILITY_MUTATION = gql(`
  mutation CreateFacility($spaceId: Int!, $data: FacilityCreateInput!) {
    createFacility(spaceId: $spaceId, data: $data) {
      ...FacilityListView
    }
  }
`);

export const UPDATE_FACILITY_MUTATION = gql(`
  mutation UpdateFacility($id: Int!, $data: FacilityInput!) {
    updateFacility(id: $id, data: $data) {
      ...FacilityListView
    }
  }
`);

export const DELETE_FACILITY_MUTATION = gql(`
  mutation DeleteFacility($id: Int!) {
    deleteFacility(id: $id)
  }
`);

export const COPY_FACILITY_MUTATION = gql(`
  mutation CopyFacility($id: Int!, $spaceId: Int!) {
    copyFacility(id: $id, spaceId: $spaceId) {
      ...FacilityListView
    }
  }
`);

export const EVICT_FACILITIES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listFacilities' });
  cache.evict({ fieldName: 'viewFacility' });
  EVICT_CONTENTPRODUCTS_QUERIES(cache);
  cache.gc();
};

export const REFETCH_FACILITIES_QUERIES = (facilityId?: number | undefined, sku?: string | undefined) => [
  ...(facilityId
    ? [
        {
          query: FACILITY_VIEW_QUERY,
          variables: { id: facilityId },
        },
      ]
    : []),
];

export const SERVICETYPES_LIST_SHORT_FRAGMENT = gql(`
  fragment ServiceTypeListShort on ServiceTypeListOutput {
    id
    sku
    name
    sequence
    isPublished
    space { ...SpaceShort }
  }
`);

export const SERVICETYPE_LIST_VIEW_FRAGMENT = gql(`
  fragment ServiceTypeListView on ServiceTypeListOutput {
    id
    name
    sku
    sequence
    isPublished
    minDayVisitors
    maxDayVisitors
    minOvernight
    maxOvernight
    minTotalGuests
    maxTotalGuests
    space { ...SpaceShort }
    products {
      id
      isForDayVisitor
      isForOvernight
      isForDeparture
      isForSemidayVisitor
      isForSemidayOvernight
      isForSemidayDeparture
      isForPrevday
      isForNextday
      isForRoom
      bundle {
        id
        name
        sku
      }
      product {
        id
        name
        sku
      }
    }
    facilities {
      id
      isForDay
      isForSemiday
      includedCount
      facility {
        id
        name
        sku
      }
    }
    addons {
      id
      approvalLimit
      selectable
      product {
        id
        name
        sku
      }
      facility {
        id
        name
        sku
      }
    }
    content {
      ...ContentFlat
    }
    validation {
      isComplete
      missingFacilities
      missingProducts
    }   
  }
`);

export const SERVICETYPES_LIST_QUERY = gql(`
  query ListServiceTypes($spaceId: Int, $hotelId: Int, $where: ServiceTypeWhereInput, $skip: Int, $take: Int) {
    listServiceTypes(spaceId: $spaceId, hotelId: $hotelId, where: $where, skip: $skip, take: $take) {
      ...ServiceTypeListShort
    }
  }
`);

export const SERVICETYPE_VIEW_QUERY = gql(`
  query ViewServiceType($id: Int!) {
    viewServiceType(id: $id) {
      ...ServiceTypeListView
    }
  }
`);

export const CREATE_SERVICETYPE_MUTATION = gql(`
  mutation CreateServiceType($spaceId: Int!, $data: ServiceTypeCreateInput!) {
    createServiceType(spaceId: $spaceId, data: $data) {
      ...ServiceTypeListView
    }
  }
`);
export const UPDATE_SERVICETYPE_MUTATION = gql(`
  mutation UpdateServiceType($id: Int!, $data: ServiceTypeInput!) {
    updateServiceType(id: $id, data: $data) {
      ...ServiceTypeListView
    }
  }
`);

export const DELETE_SERVICETYPE_MUTATION = gql(`
  mutation DeleteServiceType($id: Int!) {
    deleteServiceType(id: $id)
  }
`);

export const COPY_SERVICETYPE_MUTATION = gql(`
  mutation CopyServiceType($id: Int!, $spaceId: Int!) {
    copyServiceType(id: $id, spaceId: $spaceId) {
      ...ServiceTypeListView
    }
  }
`);

export const EVICT_SERVICETYPES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listServiceTypes' });
  cache.evict({ fieldName: 'viewServiceType' });
  EVICT_CONTENTPRODUCTS_QUERIES(cache);
  cache.gc();
};

export const REFETCH_SERVICETYPES_QUERIES = (serviceTypeId?: number | undefined, sku?: string | undefined) => [
  ...(serviceTypeId
    ? [
        {
          query: SERVICETYPE_VIEW_QUERY,
          variables: { id: serviceTypeId },
        },
      ]
    : []),
];

export const SKU_QUERY = gql(`
  query ListSKU($sku: String!) {
    listProducts(where: { sku: $sku }) {
      ...ProductListShort
    }
    listFacilities(where: { sku: $sku }) {
      ...FacilityListShort
    }
    listProductBundles(where: { sku: $sku }) {
      ...ProductBundleListShort
    }
    listServiceTypes(where: { sku: $sku }) {
      ...ServiceTypeListShort
    }
  }
`);

export const IMPORT_PRODUCTS_MUTATION = gql(`
  mutation ImportProducts($spaceId: Int!, $mode: EProductImportMergeMode!, $jsonText: String!) {
    importProducts(spaceId: $spaceId, mode: $mode, jsonText: $jsonText) {
      products {
        ...ProductListShort
      }
      bundles {
        ...ProductBundleListShort
      }
      facilities {
        ...FacilityListShort
      }
      serviceTypes {
        ...ServiceTypeListShort
      }
    }
  }
`);

export const EXPORT_PRODUCTS_MUTATION = gql(`
  mutation ExportProducts($spaceId: Int) {
    exportProducts(spaceId: $spaceId)
  }
`);

export const EVICT_IMPORT_PRODUCTS_QUERIES = (cache: ApolloCache<any>) => {
  EVICT_PRODUCTS_QUERIES(cache);
  EVICT_PRODUCTBUNDLES_QUERIES(cache);
  EVICT_FACILITIES_QUERIES(cache);
  EVICT_SERVICETYPES_QUERIES(cache);
  EVICT_HOTELS_QUERIES(cache);
  EVICT_CONTENTPRODUCTS_QUERIES(cache);
};

export const REFETCH_IMPORT_PRODUCTS_QUERIES = () => [
  ...REFETCH_PRODUCT_QUERIES(),
  ...REFETCH_PRODUCTBUNDLES_QUERIES(),
  ...REFETCH_FACILITIES_QUERIES(),
  ...REFETCH_SERVICETYPES_QUERIES(),
  ...REFETCH_HOTELS_QUERIES(),
  ...REFETCH_CONTENTPRODUCTS_QUERIES(),
];
