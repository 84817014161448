import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Grid, Paper, Toolbar, Typography } from '@mui/material';
import i18next from 'i18next';
import { extractClientErrors, extractClientErrorsSource } from 'helper/apollo';
import _ from 'lodash';

export function RedirectError(props: { err: any }) {
  const navigate = useNavigate()
  useEffect(() => {
    if (props.err) {
      navigate(`/error?err=${JSON.stringify(extractClientErrorsSource(props.err))}`)
    } else {
      navigate('/error')
    }
  }, [])
  return <></>
}

export function ErrorAlert(props: { err: any }) {
  const errs = props.err ? extractClientErrors(props.err) : null
  if (errs) {
    return <>{errs.map((e, i) => <Alert key={i} severity='error'>{e}</Alert>)}</>
  }
  return <></>
}

export default function ErrorPage() {
  const [searchParams] = useSearchParams();

  const errMsgs = []

  const errParam = searchParams.get('err')
  if (errParam) {
    try {
      const errs = JSON.parse(errParam)
      if (_.isArray(errs)) errMsgs.push(...errs)
      else errMsgs.push(`${errs}`)
    } catch (e) {
      errMsgs.push(`${errParam}`)
    }
  }

  return (<>
    <Toolbar />
    <Container component="main" maxWidth="lg">
      <Paper>
        <Grid container direction="column" padding={3} spacing={3} justifyContent="center" alignItems="center">
          <Grid item>
            <Alert severity="error">
              {i18next.t('error-generic')}
            </Alert>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => window.history.back()} color="secondary">
              {i18next.t('error-refresh')}
            </Button>
          </Grid>
          {errMsgs.length > 0 &&
            <Grid item>
              {errMsgs.map((e, i) => <Typography key={i}>{e}</Typography>)}
            </Grid>
          }
        </Grid>
      </Paper>
    </Container>
  </>);
}
