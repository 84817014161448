import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, Grid, CircularProgress, Box } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import _ from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { FormInputText, FormInputPassword } from 'components/form/FormInputText';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import InformationDialog from 'components/dialogs/InformationDialog';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId } from 'helper/security';
import { filterSelector } from 'helper/filter';

import {
  UPDATE_INTEGRATION_SECRET_MUTATION,
  CREATE_INTEGRATION_SECRET_MUTATION,
  DELETE_INTEGRATION_SECRET_MUTATION,
  INTEGRATION_SECRET_VIEW_QUERY,
  REFETCH_INTEGRATIONS_SECRETS_QUERIES,
  EVICT_INTEGRATION_SECRETS_QUERIES,
  INTEGRATION_PROPERTIES_QUERY,
} from './gql';
import yup from 'validation';
import { IntegrationSecretOutput, EIntSystem, IntegrationPMSPropertyOutput } from '__generated__/graphql';
import { canPMSTestSecret } from './pmssupport';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface SecretProps {
  id: number;
}
interface SecretCreateProps {}
interface SecretFormProps {
  data: IntegrationSecretOutput;
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  name: yup.string().required().label(i18next.t('integration-secret-name')),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  system: yup.string(),
  key: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'APALEO') return schema.required().label(i18next.t('integration-apaleo-key'));
    return schema.nullable();
  }),
  secret: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'APALEO' && isCreate) return schema.required().label(i18next.t('integration-apaleo-secret'));
    if (s === 'ELITEFO' && isCreate) return schema.required().label(i18next.t('integration-elitefo-secret'));
    return schema.nullable();
  }),
  elitefo_htuser: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'ELITEFO') return schema.required().label(i18next.t('integration-elitefo-htuser'));
    return schema.nullable();
  }),
  elitefo_htpasswd: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'ELITEFO') return schema.required().label(i18next.t('integration-elitefo-htpasswd'));
    return schema.nullable();
  }),
  protelids_pushurl: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'PROTELIDS') return schema.required().label(i18next.t('integration-protelids-pushurl'));
    return schema.nullable();
  }),
  protelids_user: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'PROTELIDS') return schema.required().label(i18next.t('integration-protelids-user'));
    return schema.nullable();
  }),
  protelids_passwd: yup.string().when(['system', 'isCreate'], ([s, isCreate], schema) => {
    if (s === 'PROTELIDS') return schema.required().label(i18next.t('integration-protelids-passwd'));
    return schema.nullable();
  }),
});

function SecretForm(props: SecretFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [propertiesResult, setPropertiesResult] = useState({
    show: false,
    properties: [] as IntegrationPMSPropertyOutput[],
  });

  const [propertiesQuery, { loading: propertiesLoading }] = useLazyQuery(INTEGRATION_PROPERTIES_QUERY);
  const [updateMutateFunction] = useMutation(UPDATE_INTEGRATION_SECRET_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_INTEGRATION_SECRET_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/settings/integration/secrets/${createdId}`);
  }, [createdId]);

  type SecretFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: IntegrationSecretOutput): SecretFormType =>
    ({
      ...obj,
      isCreate: props.data.id > 0 ? false : true,
      spaceId: obj.space.id,
    }) as any;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    getValues,
    watch,
    formState: { isValid, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as IntegrationSecretOutput),
  });
  const formValues = getValues();

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: SecretFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              system: values.system as EIntSystem,
              key: values.key,
              secret: values.secret,
              elitefo_htuser: values.elitefo_htuser,
              elitefo_htpasswd: values.elitefo_htpasswd,
              protelids_pushurl: values.protelids_pushurl,
              protelids_user: values.protelids_user,
              protelids_passwd: values.protelids_passwd,
            },
          },
          update: cache => EVICT_INTEGRATION_SECRETS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_INTEGRATIONS_SECRETS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateIntegrationSecret || {}) as IntegrationSecretOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              system: values.system as EIntSystem,
              key: values.key,
              secret: values.secret,
              elitefo_htuser: values.elitefo_htuser,
              elitefo_htpasswd: values.elitefo_htpasswd,
              protelids_pushurl: values.protelids_pushurl,
              protelids_user: values.protelids_user,
              protelids_passwd: values.protelids_passwd,
            },
          },
          update: cache => EVICT_INTEGRATION_SECRETS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_INTEGRATIONS_SECRETS_QUERIES(),
        });
        reset(toFormSchema((res.data!.createIntegrationSecret || {}) as IntegrationSecretOutput));
        setCreatedId(res.data!.createIntegrationSecret.id);
      }
      dispatchMessage(dispatch, i18next.t('integration-secret-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('integration-secrets-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid item xs={12} sm={6}>
          <FormInputText name="name" control={control} label={i18next.t('integration-secret-name')} required disabled={!canEdit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SpaceSelectionInput
            checkAdmin
            name="spaceId"
            control={control}
            disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputDropdown
            name="system"
            control={control}
            label={i18next.t('integration-secret-system')}
            options={_.orderBy(
              Object.keys(EIntSystem).map(p => ({
                value: p,
                label: `${i18next.t('enums-EIntSystem-' + p)}`,
              })),
              o => o.label,
            )}
            disabled={!canEdit || props.data.id > 0}
          />
        </Grid>
        <Grid item xs={12} sm={8}></Grid>
        {watch('system') === 'APALEO' && (
          <>
            <Grid item xs={12} sm={4}>
              <FormInputText name="key" control={control} label={i18next.t('integration-apaleo-key')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputPassword
                name="secret"
                control={control}
                label={props.data.id > 0 ? i18next.t('integration-apaleo-secretchange') : i18next.t('integration-apaleo-secret')}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </>
        )}
        {watch('system') === 'ELITEFO' && (
          <>
            <Grid item xs={12} sm={4}>
              <FormInputPassword
                name="secret"
                control={control}
                label={props.data.id > 0 ? i18next.t('integration-elitefo-secretchange') : i18next.t('integration-elitefo-secret')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputText name="elitefo_htuser" control={control} label={i18next.t('integration-elitefo-htuser')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputText name="elitefo_htpasswd" control={control} label={i18next.t('integration-elitefo-htpasswd')} />
            </Grid>
          </>
        )}
        {watch('system') === 'PROTELIDS' && (
          <>
            <Grid item xs={12} sm={4}>
              <FormInputText name="protelids_pushurl" control={control} label={i18next.t('integration-protelids-pushurl')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputText name="protelids_user" control={control} label={i18next.t('integration-protelids-user')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormInputText name="protelids_passwd" control={control} label={i18next.t('integration-protelids-passwd')} />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {canEdit && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('integration-secret-save')}
            </Button>
          )}
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={propertiesLoading ? <CircularProgress size={24} /> : <CheckIcon />}
            disabled={!canPMSTestSecret(watch('system') as EIntSystem) || propertiesLoading || !isValid}
            onClick={async () => {
              try {
                const res = await propertiesQuery({
                  variables:
                    props.data.id > 0
                      ? { secretId: props.data.id }
                      : {
                          secret: {
                            name: formValues.name,
                            system: formValues.system as EIntSystem,
                            key: formValues.key,
                            secret: formValues.secret,
                          },
                        },
                  fetchPolicy: 'network-only',
                });
                if (res.error) {
                  dispatchException(dispatch, res.error);
                } else {
                  setPropertiesResult({
                    show: true,
                    properties: res.data?.listPMSIntegrationProperties || [],
                  });
                }
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {i18next.t('integration-secret-test')}
          </Button>
          <InformationDialog
            title={i18next.t('integration-secret-test-title')}
            open={propertiesResult.show}
            onConfirm={() => setPropertiesResult({ show: false, properties: [] })}
            maxWidth="md"
          >
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  {propertiesResult.properties.length > 0 && i18next.t('integration-secret-test-result')}
                  {propertiesResult.properties.length === 0 && i18next.t('integration-secret-test-empty')}
                </Grid>
                {propertiesResult.properties.length > 0 && (
                  <Grid item xs={12}>
                    <ul>
                      {propertiesResult.properties.map((p, i) => (
                        <li key={i}>
                          {p.name} {p.extRefCode}, {p.businessName}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
              </Grid>
            </Box>
          </InformationDialog>
          {canEdit && props.data.id > 0 && <SecretDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      </Grid>
    </>
  );
}

export default function Secret(props: SecretProps) {
  const secretQuery = useQuery(INTEGRATION_SECRET_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = secretQuery.loading;
  const error = secretQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else return <SecretForm data={secretQuery.data!.viewIntegrationSecret as IntegrationSecretOutput} />;
}

export function SecretCreate(props: SecretCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  return (
    <SecretForm
      data={
        {
          id: -1,
          name: '',
          system: EIntSystem.DUMMY,
          space: { id: initialSpaceId(user, filter) } as any,
        } as IntegrationSecretOutput
      }
    />
  );
}

interface SecretDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function SecretDeleteButton(props: SecretDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_INTEGRATION_SECRET_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('integration-secret-confirm-delete')}
      confirmationTitle={i18next.t('integration-secret-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_INTEGRATION_SECRETS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_INTEGRATIONS_SECRETS_QUERIES(),
          });
          navigate('/settings/integration/secrets');
          dispatchMessage(dispatch, i18next.t('integration-secret-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('integration-secret-delete')}
    </ConfirmationButton>
  );
}
