import React, { useState } from 'react';
import _ from 'lodash';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Divider, Typography, TextField, Box, InputAdornment, IconButton } from '@mui/material';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CodeIcon from '@mui/icons-material/Code';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { getFullBackendUrl } from 'helper/download';

import { CustomTabsControlled } from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';

import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import {
  OFFERVERSION_APPROVE_MUTATION,
  OFFERVERSION_REJECT_MUTATION,
  OFFER_VIEW_QUERY,
  OFFER_AND_VERSION_VIEW_QUERY,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
} from './gql';
import {
  INTEGRATION_SYNCBOOKING_MUTATION,
  INTEGRATION_CONFIRMBOOKING_MUTATION,
  INTEGRATION_DELETEBOOKING_MUTATION,
  INTEGRATION_GROUPBOOKINGINFO_VIEW_QUERY,
  EVICT_INTEGRATION_HOTELLOG_QUERIES,
} from '../settings/integration/gql';

import { EOfferVersionStatus, EOfferVersionType, ViewWlOfferQuery, ViewWlOfferVersionQuery, WLOfferListOutput, WLOfferListViewFragment, WLOfferVersionDiffOutputFragment, WLOfferVersionListOutput, WLOfferVersionListViewFragment } from '__generated__/graphql';

import OfferVersion from './editor';
import SimpleAccordion from 'components/SimpleAccordion';
import { FormatDate, FormatDateTime } from 'components/DateTime';

import { IntegrationLogsTable } from '../settings/integration/logs';
import InformationDialog from 'components/dialogs/InformationDialog';

import { canBookingOpenPMS, canBookingSendPMS, canBookingConfirmPMS, canBookingDeletePMS } from 'pages/settings/integration/pmssupport';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface OfferProps {
  id: number;
  versionId: number;
}

interface OfferLatestProps {
  id: number;
}

interface OfferFormProps {
  data: WLOfferListViewFragment;
  version: WLOfferVersionListViewFragment & WLOfferVersionDiffOutputFragment
}

function OfferForm(props: OfferFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState(location.hash === '#editor' ? 1 : props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL ? 2 : 0);
  const [logUpdateCounter, setLogUpdateCounter] = useState(0);
  const [viewExtRefData, setViewExtRefData] = useState<string | null | undefined>(null);

  const [approveOfferVersion] = useMutation(OFFERVERSION_APPROVE_MUTATION);
  const [rejectOfferVersion] = useMutation(OFFERVERSION_REJECT_MUTATION);
  const [syncMutateFunction, { loading: syncMutateLoading }] = useMutation(INTEGRATION_SYNCBOOKING_MUTATION);
  const [confirmMutateFunction, { loading: confirmMutateLoading }] = useMutation(INTEGRATION_CONFIRMBOOKING_MUTATION);
  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(INTEGRATION_DELETEBOOKING_MUTATION);
  const [viewInfoQuery, { loading: viewInfoLoading }] = useLazyQuery(INTEGRATION_GROUPBOOKINGINFO_VIEW_QUERY);

  const isApprovable = props.version.status && (props.version.status === EOfferVersionStatus.WAITFORHOTELAPPROVAL || props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL || props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL);
  const isRejectable = props.version.status && (props.version.status === EOfferVersionStatus.WAITFORHOTELAPPROVAL || props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL);
  const isEditable =
    props.version.status &&
    [
      EOfferVersionStatus.APPROVED,
      EOfferVersionStatus.WAITFORHOTELAPPROVAL,
      EOfferVersionStatus.REJECTED_CLIENT,
      EOfferVersionStatus.REJECTED_HOTEL,
      EOfferVersionStatus.EDITING,
      EOfferVersionStatus.DELETED,
    ].indexOf(props.version.status) >= 0;

  const approveButtonTitle = props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL ? i18next.t('offer-process-approve-cr')
    : props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL ? i18next.t('offer-process-approve-as-client')
    : i18next.t('offer-process-approve')

  const rejectButtonTitle = props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL ? i18next.t('offer-process-reject-cr')
    : i18next.t('offer-process-reject')

  const setShowOfferVersionId = (versionId: number) => {
    navigate(`/offers/${props.data.id}/${versionId}#editor`);
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('offer-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {props.data.refCode} - {i18next.t(`enums-EOfferVersionStatus-${props.version.status}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.version.status !== EOfferVersionStatus.APPROVED && <ConfirmationButton
            sx={{ marginRight: 2 }}
            disabled={!isApprovable}
            variant="contained"
            confirmationQuestion={props.version.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL ? i18next.t('offerconfirm-approve-as-client-text') : props.version.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL ? i18next.t('offerconfirm-approve-cr') : i18next.t('offerconfirm-approve-text')}
            confirmationTitle={i18next.t('offerconfirm-approve-title')}
            startIcon={<ThumbUpIcon />}
            onConfirm={async () => {
              try {
                const res = await approveOfferVersion({
                  variables: {
                    offerVersionId: props.version.id,
                  },
                  update: cache => {
                    EVICT_OFFER_QUERIES(cache);
                    EVICT_INTEGRATION_HOTELLOG_QUERIES(cache);
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                });
                dispatchMessage(dispatch, i18next.t('offer-status-approved'));
                navigate(`/offers/${props.data.id}/${res.data?.approveOfferVersion?.id}#editor`);
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {approveButtonTitle}
          </ConfirmationButton>}
          {props.version.status !== EOfferVersionStatus.APPROVED && <ConfirmationButton
            sx={{ marginRight: 2 }}
            disabled={!isRejectable}
            variant="contained"
            color="secondary"
            confirmationQuestion={i18next.t('offerconfirm-reject-text')}
            confirmationTitle={i18next.t('offerconfirm-reject-title')}
            startIcon={<ThumbDownIcon />}
            onConfirm={async () => {
              try {
                await rejectOfferVersion({
                  variables: {
                    offerVersionId: props.version.id,
                  },
                  update: cache => {
                    EVICT_OFFER_QUERIES(cache);
                    EVICT_INTEGRATION_HOTELLOG_QUERIES(cache);
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                });
                dispatchMessage(dispatch, i18next.t('offer-status-rejected'));
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {rejectButtonTitle}
          </ConfirmationButton>}
          <Button
            sx={{ marginRight: 2 }}
            disabled={!isEditable}
            variant="contained"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={() => {
              setSelectedTab(1);
            }}
          >
            {props.version.type === EOfferVersionType.RESERVATION_CONFIRMATION && i18next.t('offer-confirmation-process-edit')}
            {props.version.type === EOfferVersionType.OFFER && i18next.t('offer-process-edit')}
          </Button>
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
            onClick={() => {
              window.open(getFullBackendUrl(`/backend/pdf/downloadOffer/${props.version.id}`), '_blank');
            }}
          >
            {props.version.type === EOfferVersionType.OFFER ? i18next.t('offer-process-download') : i18next.t('offer-confirmation-process-download')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomTabsControlled
            selected={selectedTab}
            handleChange={selected => setSelectedTab(selected)}
            headers={[
              i18next.t('offer-input-tab'),
              props.version.type === EOfferVersionType.RESERVATION_CONFIRMATION ? i18next.t('offer-confirmation-editor-tab') : i18next.t('offer-editor-tab'),
              i18next.t('offer-approvecr-tab'),
              i18next.t('offer-integration-tab'),
              i18next.t('offer-history-tab'),
            ]}
            disabled={[false, false, false, false, false]}
            hidden={[false, false, props.version.status !== EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL, props.data.hotel.integrations.length === 0, false]}
            icons={[undefined, undefined, <ErrorIcon />, undefined, undefined ]}
            tabs={[
              <Grid container>
                {!props.version.input && (
                  <Grid item xs={6}>
                    Nicht vorhanden
                  </Grid>
                )}
                {props.version.input && (<>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={8} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-email')}</Typography>
                            {props.version.client.email}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-phone')}</Typography>
                            {props.version.client.phone || '-'}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-start')}</Typography>
                            <FormatDate date={props.version.input!.startDate} />
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-end')}</Typography>
                            <FormatDate date={props.version.input!.endDate} />
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-servicetype')}</Typography>
                            {props.version.input!.serviceType?.header}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-comment')}</Typography>
                            {props.version.input!.comment || '-'}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5">{i18next.t('offer-input-header-company-data')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-name')}</Typography>
                            {props.version.client.firstname} {props.version.client.lastname}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-company')}</Typography>
                            {props.version.client.company}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-address')}</Typography>
                            {props.version.client.address}
                            <br />
                            {props.version.client.zip} {props.version.client.city}
                            <br />
                            {props.version.client.country}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <Typography variant="h5">{i18next.t('offer-input-header-billing-data')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-name')}</Typography>
                            {props.version.client.billingFirstname} {props.version.client.billingLastname}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-company')}</Typography>
                            {props.version.client.billingCompany}
                          </Grid>
                          <Grid item xs={4} sx={{ padding: 1 }}>
                            <Typography variant="h6">{i18next.t('offer-input-header-address')}</Typography>
                            {props.version.client.billingAddress}
                            <br />
                            {props.version.client.billingZip} {props.version.client.billingCity}
                            <br />
                            {props.version.client.billingCountry}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} sx={{ padding: 1 }}>
                        <SimpleAccordion autoOpen={true} header={<>{i18next.t('offer-input-header-preday')}</>}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography variant="subtitle2">{i18next.t('offer-input-header-guests')}</Typography>
                              {props.version.input!.prevdayGuests}
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      {props.version.input!.days.map((day, index) => (
                        <Grid key={index} item xs={12} sx={{ padding: 1 }}>
                          <SimpleAccordion
                            autoOpen={true}
                            header={
                              <>
                                {i18next.t('offer-input-header-day')} {day.day + 1}
                              </>
                            }
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={4} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-guests')}:</Typography> {day.totalGuests}
                                  </Grid>
                                  <Grid item xs={4} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-overnightguests')}:</Typography>{' '}
                                    {day.overnightGuests}
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-rooms')}:</Typography>
                                    <SimpleTable
                                      headers={[
                                        i18next.t('offer-occupancy-header-room'),
                                        i18next.t('offer-occupancy-header-occupancy'),
                                        i18next.t('offer-occupancy-header-seating'),
                                      ]}
                                      rows={day.occupancy.map((row, index) => [
                                        row.room,
                                        i18next.t(`enums-EOfferRoomOccupancy-${row.occupancy}`),
                                        i18next.t(`enums-EOfferRoomSeating-${row.seating}`),
                                      ])}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <Typography variant="subtitle2">{i18next.t('offer-input-header-addons')}:</Typography>
                                    {day.addons.length === 0 && <Typography variant="body2">{i18next.t('offer-input-no-addons')}</Typography>}
                                    {day.addons.length > 0 && (
                                      <SimpleTable
                                        headers={[i18next.t('offer-addons-header-name'), i18next.t('offer-addons-header-count')]}
                                        rows={day.addons.map((row, index) => [
                                          <>
                                            {row.product && <Link to={`/products/product/${row.product.id}`}>{row.product.name}</Link>}
                                            {row.facility && <Link to={`/products/facility/${row.facility.id}`}>{row.facility.name}</Link>}
                                          </>,
                                          row.count,
                                        ])}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>)}
              </Grid>,
              <OfferVersion versionId={props.version.id} offerId={props.data.id} onShowOfferVersion={id => setShowOfferVersionId(id)} />,
              <Grid container>
                {props.version.diffToApproved && (
                  <Grid item xs={6}>
                    <Grid container>
                      {props.version.diffToApproved!.diff.map((dayDiff, index) => (
                        <Grid key={index} item xs={12} sx={{ padding: 1 }}>
                          <SimpleAccordion
                            autoOpen={true}
                            header={
                              <>
                                {dayDiff.day === -1 && i18next.t('offer-input-header-preday')}
                                {dayDiff.day >= 0 && <>{i18next.t('offer-input-header-day')} {dayDiff.day + 1}</>} 
                              </>
                            }
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12} sx={{ padding: 1 }}>
                                    <SimpleTable
                                      headers={[
                                        i18next.t('offer-input-line-sku'),
                                        i18next.t('offer-input-line-header'),
                                        i18next.t('offer-input-line-count1'),
                                        i18next.t('offer-input-line-count2'),
                                        i18next.t('offer-input-line-diff'),
                                      ]}
                                      rows={dayDiff.diffs.map((row, index) => [
                                        row.sku,
                                        row.header,
                                        row.count1,
                                        row.count2,
                                        row.diff > 0 ? `+${row.diff}`: row.diff
                                      ])}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>                  
                )}
              </Grid>,              
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={viewInfoLoading ? <CircularProgress size={24} /> : <OpenInNewIcon />}
                    disabled={!canBookingOpenPMS(props.version) || viewInfoLoading}
                    onClick={async () => {
                      try {
                        const res = await viewInfoQuery({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                        });
                        if (res.data?.integrationViewGroupBookingInfo && res.data?.integrationViewGroupBookingInfo.href) {
                          window.open(res.data?.integrationViewGroupBookingInfo.href, '_blank');
                        } else {
                          dispatchMessage(dispatch, i18next.t('offer-process-openbooking-nohref'));
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-openbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={syncMutateLoading ? <CircularProgress size={24} /> : <SyncIcon />}
                    disabled={!canBookingSendPMS(props.version) || syncMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await syncMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationSyncBooking) {
                          if (res.data?.integrationSyncBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-syncbooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-syncbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={confirmMutateLoading ? <CircularProgress size={24} /> : <CheckIcon />}
                    disabled={!canBookingConfirmPMS(props.version) || confirmMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await confirmMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationConfirmBooking) {
                          if (res.data?.integrationConfirmBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-confirmbooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-confirmbooking')}
                  </Button>
                  <Button
                    sx={{ marginRight: 2 }}
                    variant="contained"
                    color="secondary"
                    startIcon={deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                    disabled={!canBookingDeletePMS(props.version) || deleteMutateLoading}
                    onClick={async () => {
                      try {
                        const res = await deleteMutateFunction({
                          variables: {
                            offerVersionId: props.version.id,
                          },
                          update: cache => EVICT_INTEGRATION_HOTELLOG_QUERIES(cache),
                          refetchQueries: REFETCH_OFFER_QUERIES(props.data.id, props.version.id),
                        });
                        if (res.data?.integrationDeleteBooking) {
                          if (res.data?.integrationDeleteBooking.status === 'SUCCESS') {
                            dispatchMessage(dispatch, i18next.t('offer-process-deletebooking-success'));
                          }
                          setLogUpdateCounter(logUpdateCounter + 1);
                        }
                      } catch (err) {
                        dispatchException(dispatch, err);
                      }
                    }}
                  >
                    {i18next.t('offer-process-deletebooking')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label={i18next.t('offer-process-extrefcode')}
                    value={props.version.extRefCode || ''}
                    disabled
                    InputProps={{
                      endAdornment: props.version.extRefData && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setViewExtRefData(props.version.extRefData)}>
                            <CodeIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: 1 }}></Grid>
                <Grid item xs={12}>
                  <IntegrationLogsTable key={logUpdateCounter} variables={{ spaceId: props.data.space.id, offerId: props.data.id }} />
                </Grid>
                <InformationDialog
                  title={i18next.t('offer-process-extrefdata')}
                  open={!!viewExtRefData}
                  onConfirm={() => setViewExtRefData(null)}
                  maxWidth="lg"
                  fullWidth
                >
                  <Box p={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <pre>{viewExtRefData && JSON.stringify(JSON.parse(viewExtRefData), null, 2)}</pre>
                      </Grid>
                    </Grid>
                  </Box>
                </InformationDialog>
              </Grid>,
              <SimpleTable
                headers={[
                  i18next.t('offer-versions-header-createdat'),
                  i18next.t('offer-versions-header-version'),
                  i18next.t('offer-versions-header-status'),
                  i18next.t('offer-versions-header-user'),
                  '',
                ]}
                rows={_.orderBy(
                  [
                    ...props.data.history.map(h => ({
                      createdAt: h.createdAt,
                      version: h.offerVersion ? h.offerVersion.version : '',
                      status: i18next.t(`enums-EOfferVersionHistoryStatus-${h.status}`),
                      username: h.user?.name,
                      action: h.extraInfo ? <>{h.extraInfo}</> : h.offerVersion ? (
                        <Button
                          variant="text"
                          onClick={() => {
                            setShowOfferVersionId(h.offerVersion!.id);
                          }}
                        >
                          {h.offerVersion!.status === EOfferVersionStatus.EDITING ? i18next.t('offer-history-edit') : i18next.t('offer-history-view')}
                        </Button>
                      ) : null,
                    })),
                    ...props.data.emailHistory
                      .filter(e => e.success)
                      .map(e => ({
                        createdAt: e.createdAt,
                        version: e.offerVersion ? e.offerVersion.version : '',
                        status: i18next.t('offer-history-email-sent'),
                        username: e.userName,
                        action: (
                          <Grid container>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-subject')}: <b>{e.subject}</b>
                            </Grid>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-from')}: {e.from}
                            </Grid>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-to')}: {e.to}
                            </Grid>
                          </Grid>
                        ),
                      })),
                    ...props.data.emailHistory
                      .filter(e => !e.success)
                      .map(e => ({
                        createdAt: e.createdAt,
                        version: e.offerVersion ? e.offerVersion.version : '',
                        status: i18next.t('offer-history-email-failed'),
                        username: e.userName,
                        action: (
                          <Grid container>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-subject')}: <b>{e.subject}</b>
                            </Grid>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-from')}: {e.from}
                            </Grid>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-to')}: {e.to}
                            </Grid>
                            <Grid item xs={12}>
                              {i18next.t('offer-history-email-err')}: {e.err}
                            </Grid>
                          </Grid>
                        ),
                      })),
                  ],
                  l => l.createdAt,
                  'desc',
                ).map((row, index) => [
                  <FormatDateTime date={row.createdAt} />,
                  row.version || null,
                  row.status || null,
                  row.username || null,
                  row.action || null,
                ])}
              />,
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default function Offer(props: OfferProps) {
  const offerQuery = useQuery(OFFER_AND_VERSION_VIEW_QUERY, {
    variables: { id: props.id, versionId: props.versionId }
  });

  const loading = offerQuery.loading;
  const error = offerQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else if (!offerQuery.data?.viewWlOffer || !offerQuery.data?.viewWlOfferVersion) return <Navigate to={`/offers/${props.id}`} />
  else 
    return (
      <OfferForm data={offerQuery.data!.viewWlOffer!} version={offerQuery.data!.viewWlOfferVersion!} />
    );
}

export function OfferLatest(props: OfferLatestProps) {
  const offerQuery = useQuery(OFFER_VIEW_QUERY, {
    variables: { id: props.id },
    fetchPolicy: 'network-only',
  });

  const loading = offerQuery.loading;
  const error = offerQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <OfferForm
        data={offerQuery.data!.viewWlOffer!}
        version={offerQuery.data!.viewWlOffer!.latestVersion}
      />
    );
}
