import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlusIcon from '@mui/icons-material/Add';
import { CircularProgress, Alert, Grid, Button, IconButton, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useMutation, useQuery } from '@apollo/client';
import {
  OFFER_LIST_QUERY_OPEN,
  OFFER_LIST_QUERY_CLOSED,
  OFFERVERSION_APPROVE_MUTATION,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
  OFFERVERSION_DELETE_MUTATION,
  OFFERVERSION_REJECT_MUTATION,
  OFFER_SEND_REMINDER_CLIENT_MUTATION,
  OFFER_LIST_QUERY_DELETED,
} from './gql';
import SimpleTable from 'components/table/SimpleTable';
import CustomTabs from 'components/Tabs';
import { EOfferStatus, EOfferVersionStatus, ListWlOffersOpenQuery } from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import SimpleAccordion from 'components/SimpleAccordion';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { FormatDateTime, FormatDateRange } from 'components/DateTime';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/ForwardToInbox';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';

export default function OffersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = filterSelector();

  const _renderOffersTable = (
    title: string,
    offers: ListWlOffersOpenQuery['listWlOffers'],
    setRejectOfferVersionId: (id: number) => void,
    setApproveOfferVersionId: (id: number) => void,
    setDeleteOfferVersionId: (id: number) => void,
    setShowRejectDialog: (show: boolean) => void,
    setShowApproveDialog: (show: boolean) => void,
    setShowDeleteDialog: (show: boolean) => void,
    sendOfferReminderEmailClient?: any,
  ) => {
    return (
      <SimpleAccordion header={title} autoOpen={true}>
        <SimpleTable
          headers={[
            i18next.t('offers-list-header-createdat'),
            i18next.t('offers-list-header-refcode'),
            i18next.t('offers-list-header-hotel'),
            i18next.t('offers-list-header-from-to'),
            i18next.t('offers-list-header-client'),
            i18next.t('offers-list-header-status'),
            i18next.t('offers-list-header-updatedat'),
            '',
            '',
            ...(sendOfferReminderEmailClient ? [''] : []),
          ]}
          rows={
            offers.map((row, index) => [
              <FormatDateTime date={row.createdAt} />,
              <Link to={`/offers/${row.id}/${row.latestVersion?.id}`}>{row.refCode}</Link>,
              <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
              <FormatDateRange from={row.latestVersion.input?.startDate} to={row.latestVersion.input?.endDate} />,
              row.latestVersion.client?.lastname + ' ' + row.latestVersion.client?.firstname + ' / ' + row.latestVersion.client?.company,
              i18next.t(`enums-EOfferVersionStatus-${row.latestVersion?.status}`),
              <FormatDateTime date={row.latestVersion?.updatedAt} />,
              <IconButton
                title={`${i18next.t('offers-list-delete')}`}
                onClick={() => {
                  setDeleteOfferVersionId(row.latestVersion!.id);
                  setShowDeleteDialog(true);
                }}
              >
                <DeleteIcon />
              </IconButton>,
              <IconButton title={`${i18next.t('offers-list-show')}`} onClick={() => navigate(`/offers/${row.id}/${row.latestVersion?.id}`)}>
                <OpenIcon />
              </IconButton>,
              <>
                {sendOfferReminderEmailClient && (
                  <IconButton
                    title={`${i18next.t('offer-reminder-email-send')}`}
                    onClick={async () => {
                      try {
                        await sendOfferReminderEmailClient({
                          variables: {
                            offerVersionId: row.latestVersion!.id,
                          },
                        });
                        dispatchMessage(dispatch, i18next.t('offer-reminder-email-sent'));
                      } catch (error: any) {
                        dispatchException(dispatch, error);
                      }
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                )}
              </>,
            ]) || []
          }
        />
      </SimpleAccordion>
    );
  };

  const queryOpen = useQuery(OFFER_LIST_QUERY_OPEN, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
    },
  });
  const queryClosed = useQuery(OFFER_LIST_QUERY_CLOSED, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
    },
  });
  const queryDeleted = useQuery(OFFER_LIST_QUERY_DELETED, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
    },
  });
  const [approveOfferVersion] = useMutation(OFFERVERSION_APPROVE_MUTATION);
  const [rejectOfferVersion] = useMutation(OFFERVERSION_REJECT_MUTATION);
  const [deleteOfferVersion] = useMutation(OFFERVERSION_DELETE_MUTATION);

  const [sendOfferReminderEmailClient] = useMutation(OFFER_SEND_REMINDER_CLIENT_MUTATION);

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [deleteOfferVersionId, setDeleteOfferVersionId] = React.useState<number | null>(null);

  const [showApproveDialog, setShowApproveDialog] = React.useState(false);
  const [approveOfferVersionId, setApproveOfferVersionId] = React.useState<number | null>(null);

  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [rejectOfferVersionId, setRejectOfferVersionId] = React.useState<number | null>(null);

  const [deleteReason, setDeleteReason] = React.useState('');

  const loading = queryOpen.loading || queryClosed.loading || queryDeleted.loading;
  const error = queryOpen.error || queryClosed.error || queryDeleted.error;

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;

  return (
    <>
      <Helmet>
        <title>{i18next.t('offer-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            sx={{ marginBottom: 2 }}
            onClick={async () => {
              navigate('/offers/_create');
            }}
          >
            <PlusIcon /> Neues Angebot erstellen
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            sx={{ marginBottom: 2 }}
            onClick={() => {
              queryOpen.refetch();
              queryClosed.refetch();
              queryDeleted.refetch();
            }}
          >
            <RefreshIcon /> Aktualisieren
          </Button>
        </Grid>
      </Grid>
      <CustomTabs
        headers={[
          i18next.t('offers-list-open'),
          i18next.t('offers-list-approved'),
          i18next.t('offers-list-rejected'),
          i18next.t('offers-list-closed'),
          i18next.t('offers-list-deleted'),
        ]}
        tabs={[
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {_renderOffersTable(
                i18next.t('offers-list-open-waitforhotelapproval', {
                  count: queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.WAITFORHOTELAPPROVAL)
                    .length,
                }),
                queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.WAITFORHOTELAPPROVAL) || [],
                setRejectOfferVersionId,
                setApproveOfferVersionId,
                setDeleteOfferVersionId,
                setShowRejectDialog,
                setShowApproveDialog,
                setShowDeleteDialog,
              )}
            </Grid>
            <Grid item xs={12}>
              {_renderOffersTable(
                i18next.t('offers-list-open-waitforhotelcrapproval', {
                  count: queryClosed.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL)
                    .length,
                }),
                queryClosed.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL) || [],
                setRejectOfferVersionId,
                setApproveOfferVersionId,
                setDeleteOfferVersionId,
                setShowRejectDialog,
                setShowApproveDialog,
                setShowDeleteDialog,
              )}
            </Grid>            
            <Grid item xs={12}>
              {_renderOffersTable(
                i18next.t('offers-list-open-editing', {
                  count: queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.EDITING).length,
                }),
                _.orderBy(
                  queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.EDITING) || [],
                  'updatedAt',
                  'desc',
                ),
                setRejectOfferVersionId,
                setApproveOfferVersionId,
                setDeleteOfferVersionId,
                setShowRejectDialog,
                setShowApproveDialog,
                setShowDeleteDialog,
              )}
            </Grid>
            <Grid item xs={12}>
              {_renderOffersTable(
                i18next.t('offers-list-open-waitforclientapproval', {
                  count: queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL)
                    .length,
                }),
                queryOpen.data?.listWlOffers.filter(offer => offer.latestVersion?.status === EOfferVersionStatus.WAITFORCLIENTAPPROVAL) || [],
                setRejectOfferVersionId,
                setApproveOfferVersionId,
                setDeleteOfferVersionId,
                setShowRejectDialog,
                setShowApproveDialog,
                setShowDeleteDialog,
                sendOfferReminderEmailClient,
              )}
            </Grid>
          </Grid>,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              (
                queryClosed.data?.listWlOffers.filter(
                  offer => offer.status === EOfferStatus.APPROVED && moment(offer.latestVersion.input?.endDate).isAfter(moment()),
                ) || []
              ).map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                ,
                <Link to={`/offers/${row.id}/${row.latestVersion?.id}`}>{row.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.latestVersion.input?.startDate} to={row.latestVersion.input?.endDate} />,
                row.latestVersion.client?.lastname + ' ' + row.latestVersion.client?.firstname + ' / ' + row.latestVersion.client?.company,

                i18next.t(`enums-EOfferStatus-${row.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.latestVersion!.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              (
                queryClosed.data?.listWlOffers.filter(
                  offer => offer.status === EOfferStatus.REJECTED && moment(offer.latestVersion.input?.endDate).isAfter(moment()),
                ) || []
              ).map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                ,
                <Link to={`/offers/${row.id}/${row.latestVersion?.id}`}>{row.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.latestVersion.input?.startDate} to={row.latestVersion.input?.endDate} />,
                row.latestVersion.client?.lastname + ' ' + row.latestVersion.client?.firstname + ' / ' + row.latestVersion.client?.company,

                i18next.t(`enums-EOfferStatus-${row.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.latestVersion!.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              '',
              '',
            ]}
            rows={
              (queryClosed.data?.listWlOffers.filter(offer => moment(offer.latestVersion.input?.endDate).isBefore(moment())) || []).map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.id}/${row.latestVersion?.id}`}>{row.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.latestVersion.input?.startDate} to={row.latestVersion.input?.endDate} />,
                row.latestVersion.client?.lastname + ' ' + row.latestVersion.client?.firstname + ' / ' + row.latestVersion.client?.company,

                i18next.t(`enums-EOfferStatus-${row.status}`),
                <IconButton
                  title={`${i18next.t('offers-list-delete')}`}
                  onClick={() => {
                    setDeleteOfferVersionId(row.latestVersion!.id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
          <SimpleTable
            headers={[
              i18next.t('offers-list-header-createdat'),
              i18next.t('offers-list-header-refcode'),
              i18next.t('offers-list-header-hotel'),
              i18next.t('offers-list-header-from-to'),
              i18next.t('offers-list-header-client'),
              i18next.t('offers-list-header-status'),
              i18next.t('offers-list-header-delete-reason'),
              '',
            ]}
            rows={
              queryDeleted.data?.listWlOffers.map((row, index) => [
                <FormatDateTime date={row.createdAt} />,
                <Link to={`/offers/${row.id}/${row.latestVersion?.id}`}>{row.refCode}</Link>,
                <Link to={`/settings/hotels/${row.hotel.id}`}>{row.hotel.name}</Link>,
                <FormatDateRange from={row.latestVersion.input?.startDate} to={row.latestVersion.input?.endDate} />,
                row.latestVersion.client?.lastname + ' ' + row.latestVersion.client?.firstname + ' / ' + row.latestVersion.client?.company,
                i18next.t(`enums-EOfferStatus-${row.status}`),
                row.deleteReason,
                <IconButton title={`${i18next.t('offers-list-view')}`} onClick={() => navigate(`/offers/${row.id}`)}>
                  <OpenIcon />
                </IconButton>,
              ]) || []
            }
          />,
        ]}
      />

      <ConfirmationDialog
        title={i18next.t('offerconfirm-delete-title')}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        keepOpenOnConfirm={true}
        onConfirm={async () => {
          if (deleteReason.length === 0) {
            return;
          } else {
            try {
              await deleteOfferVersion({
                variables: {
                  offerVersionId: deleteOfferVersionId!,
                  deleteReason,
                },
                update: cache => EVICT_OFFER_QUERIES(cache),
                awaitRefetchQueries: true,
                refetchQueries: REFETCH_OFFER_QUERIES(),
              });
              setDeleteReason('');
              setShowDeleteDialog(false);
              dispatchMessage(dispatch, i18next.t('offerconfirm-delete-success'));
              queryOpen.refetch();
              queryClosed.refetch();
              queryDeleted.refetch();
            } catch (err) {
              dispatchException(dispatch, err);
            }
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {i18next.t('offerconfirm-delete-text')}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={deleteReason.length === 0}
              fullWidth
              label={i18next.t('offerconfirm-delete-reason')}
              value={deleteReason}
              onChange={e => setDeleteReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmationDialog>

      <ConfirmationDialog
        title={i18next.t('offerconfirm-approve-title')}
        open={showApproveDialog}
        setOpen={setShowApproveDialog}
        onConfirm={async () => {
          try {
            await approveOfferVersion({
              variables: {
                offerVersionId: approveOfferVersionId!,
              },
              update: cache => EVICT_OFFER_QUERIES(cache),
              awaitRefetchQueries: true,
              refetchQueries: REFETCH_OFFER_QUERIES(),
            });
            dispatchMessage(dispatch, i18next.t('offerconfirm-approve-success'));
            queryOpen.refetch();
            queryClosed.refetch();
            queryDeleted.refetch();
          } catch (err) {
            dispatchException(dispatch, err);
          }
        }}
      >
        {i18next.t('offerconfirm-approve-text')}
      </ConfirmationDialog>

      <ConfirmationDialog
        title={i18next.t('offerconfirm-reject-title')}
        open={showRejectDialog}
        setOpen={setShowRejectDialog}
        onConfirm={async () => {
          try {
            await rejectOfferVersion({
              variables: {
                offerVersionId: rejectOfferVersionId!,
              },
              update: cache => EVICT_OFFER_QUERIES(cache),
              awaitRefetchQueries: true,
              refetchQueries: REFETCH_OFFER_QUERIES(),
            });
            dispatchMessage(dispatch, i18next.t('offerconfirm-reject-success'));
            queryOpen.refetch();
            queryClosed.refetch();
            queryDeleted.refetch();
          } catch (err) {
            dispatchException(dispatch, err);
          }
        }}
      >
        {i18next.t('offerconfirm-reject-text')}
      </ConfirmationDialog>
    </>
  );
}
