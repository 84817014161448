import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Button, IconButton, Tooltip, Grid, CircularProgress, Divider, Select, MenuItem } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert } from 'helper/form';
import { exportToFile } from 'helper/download';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleAccordion from 'components/SimpleAccordion';
import { EditorAccordionDummy } from 'components/template_editor/EditorAccordion';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';

import {
  OFFERTEMPLATE_VIEW_QUERY,
  UPDATE_CONTENTOFFERTEMPLATE_MUTATION,
  CREATE_CONTENTOFFERTEMPLATE_MUTATION,
  DELETE_CONTENTOFFERTEMPLATE_MUTATION,
  COPY_CONTENTOFFERTEMPLATE_MUTATION,
  EVICT_OFFERTEMPLATES_QUERIES,
  REFETCH_OFFERTEMPLATES_QUERIES,
  EXPORT_CONTENTOFFERTEMPLATE_MUTATION,
} from '../gql';
import { ContentOfferTemplateListOutput, EContentOfferTemplateBlockType, EContentOfferTemplateBlockFormFieldType, EContentOfferTemplateType } from '__generated__/graphql';
import { HTMLOfferTemplateRenderer } from 'components/template_editor/HTMLRenderer';
import { EWL_OfferVersionContentBlockType } from '../../../semshared/offer/offer';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId } from 'helper/security';
import { filterSelector } from 'helper/filter';
import { FormInputDropdown } from 'components/form/FormInputDropdown';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface OfferTemplateProps {
  id: number;
}
interface OfferTemplateCreateProps {}
interface OfferTemplateFormProps {
  data: ContentOfferTemplateListOutput;
}

const contentBlockCustomFormSchema = yup.object().shape({
  sequence: yup.number().required(),
  name: yup.string().required(),
  type: yup.string().required(),
  defaultValue: yup.string().nullable(),
  label: yup.string().required(),
  required: yup.boolean().nullable(),
  placeholder: yup.string().nullable(),
  helpText: yup.string().nullable(),
});

const contentBlockSchema = yup.object().shape({
  sequence: yup.number().required(),
  template: yup.string().required().label(i18next.t('offerblock-plainhtml-html')),
  removable: yup.boolean().required(),
  type: yup.mixed<EWL_OfferVersionContentBlockType>().required(),
  blockDescription: yup.string().nullable(),
  formFields: yup.array().of(contentBlockCustomFormSchema).label(i18next.t('offerblock-customform-fields')),
});

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  id: yup.number().required(),
  spaceId: yup.number().required().label(i18next.t('field-space')),
  name: yup.string().required().label(i18next.t('offertemplate-name')),
  title: yup.string().nullable(),
  filename: yup.string().nullable(),
  isPublished: yup.boolean().required(),
  type: yup.mixed<EContentOfferTemplateType>().required(),
  includeEmptyLineItems: yup.boolean().required(),
  pageOptions: yup.object().shape({
    marginTop: yup.number().required().label(i18next.t('offertemplate-marginTop')),
    marginBottom: yup.number().required().label(i18next.t('offertemplate-marginBottom')),
    marginLeft: yup.number().required().label(i18next.t('offertemplate-marginLeft')),
    marginRight: yup.number().required().label(i18next.t('offertemplate-marginRight')),
    cssStyles: yup.string().nullable(),
    headerTemplate: yup.string().nullable(),
    footerTemplate: yup.string().nullable(),
  }),
  contentBlocks: yup.array().required().min(1).of(contentBlockSchema).label(i18next.t('offertemplate-layout')),
});

function OfferTemplateForm(props: OfferTemplateFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateMutateFunction] = useMutation(UPDATE_CONTENTOFFERTEMPLATE_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_CONTENTOFFERTEMPLATE_MUTATION);
  const [exportMutateFunction, { loading: exportLoading }] = useMutation(EXPORT_CONTENTOFFERTEMPLATE_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/content/templates/offertemplates/${createdId}`);
  }, [createdId]);

  const [renderCycle, setRenderCycle] = useState(0);

  const [contentBlockAdd, setContentBlockAdd] = useState<any>({
    value: 'NULL',
    position: 'end',
  });

  useEffect(() => {
    if (contentBlockAdd.value === 'NULL') return;
    const cbFunc = contentBlockAdd.position === 'start' ? contentBlocksPrepend : contentBlocksAppend;
    if (contentBlockAdd.value === 'CUSTOM_FORM')
      cbFunc({
        sequence: contentBlockAdd.position === 'start' ? 0 : 1000000,
        type: contentBlockAdd.value,
        template: '',
        removable: true,
      });
    if (contentBlockAdd.value === 'PLAIN_HTML')
      cbFunc({
        sequence: contentBlockAdd.position === 'start' ? 0 : 1000000,
        type: contentBlockAdd.value,
        template: '',
        removable: true,
      });
    if (contentBlockAdd.value === 'LINE_ITEMS')
      cbFunc({
        sequence: contentBlockAdd.position === 'start' ? 0 : 1000000,
        type: contentBlockAdd.value,
        template: '',
        removable: true,
      });
    if (contentBlockAdd.value === 'INPUT_DAYS')
      cbFunc({
        sequence: contentBlockAdd.position === 'start' ? 0 : 1000000,
        type: contentBlockAdd.value,
        template: '',
        removable: true,
      });
    _reassignSequence(getValues('contentBlocks'));
    setContentBlockAdd({ value: 'NULL', position: 'end' });
  }, [contentBlockAdd]);

  type ContentOfferTemplateFormType = yup.InferType<typeof validationSchema>;
  type ContentBlockFormType = yup.InferType<typeof contentBlockSchema>;

  const toFormSchema = (obj: ContentOfferTemplateListOutput): ContentOfferTemplateFormType => ({
    isCreate: props.data.id > 0 ? false : true,
    id: obj.id,
    spaceId: obj.space.id,
    name: obj.name,
    title: obj.title,
    filename: obj.filename,
    isPublished: obj.isPublished,
    includeEmptyLineItems: obj.includeEmptyLineItems,
    type: obj.type as EContentOfferTemplateType,
    contentBlocks: obj.contentBlocks.map(cb => ({
      ...cb,
      type: cb.type as EWL_OfferVersionContentBlockType,
      formFields: cb.formFields?.map(ff => ({
        sequence: ff.sequence,
        name: ff.name,
        label: ff.label,
        defaultValue: ff.defaultValue,
        placeholder: ff.placeholder,
        required: ff.required,
        helpText: ff.helpText,
        type: ff.type as string,
      })),
    })),
    pageOptions: {
      marginTop: obj.marginTop || 0,
      marginBottom: obj.marginBottom || 0,
      marginLeft: obj.marginLeft || 0,
      marginRight: obj.marginRight || 0,
      cssStyles: obj.cssStyles,
      headerTemplate: obj.headerTemplate,
      footerTemplate: obj.footerTemplate,
    },
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as ContentOfferTemplateListOutput),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = form;

  const {
    fields: contentBlocksFields,
    append: contentBlocksAppend,
    prepend: contentBlocksPrepend,
    remove: contentBlocksRemove,
    replace: contentBlocksReplace,
  } = useFieldArray({
    control,
    name: 'contentBlocks',
  });

  const onSubmit = async (values: ContentOfferTemplateFormType) => {
    try {
      if (props.data && props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data!.id,
            data: {
              contentBlocks: values.contentBlocks.map((cb, index) => ({
                template: cb.template,
                removable: cb.removable,
                sequence: index + 1,
                blockDescription: cb.blockDescription || null,
                formFields:
                  cb.formFields?.map(ff => ({
                    sequence: ff.sequence,
                    name: ff.name,
                    label: ff.label,
                    defaultValue: ff.defaultValue,
                    placeholder: ff.placeholder,
                    required: ff.required,
                    helpText: ff.helpText,
                    type: ff.type as EContentOfferTemplateBlockFormFieldType,
                  })) || [],
                type: cb.type as EContentOfferTemplateBlockType,
              })),
              name: values.name,
              title: values.title || null,
              filename: values.filename || null,
              isPublished: !!values.isPublished,
              includeEmptyLineItems: !!values.includeEmptyLineItems,
              marginTop: values.pageOptions.marginTop,
              marginBottom: values.pageOptions.marginBottom,
              marginLeft: values.pageOptions.marginLeft,
              marginRight: values.pageOptions.marginRight,
              cssStyles: values.pageOptions.cssStyles || null,
              headerTemplate: values.pageOptions.headerTemplate || null,
              footerTemplate: values.pageOptions.footerTemplate || null,
              type: values.type,
            },
          },
          update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateContentOfferTemplate || {}) as ContentOfferTemplateListOutput));
        dispatchMessage(dispatch, i18next.t('offertemplate-updated'));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              contentBlocks: values.contentBlocks.map((cb, index) => ({
                template: cb.template,
                removable: cb.removable,
                sequence: index + 1,
                blockDescription: cb.blockDescription || null,
                formFields:
                  cb.formFields?.map(ff => ({
                    sequence: ff.sequence,
                    name: ff.name,
                    label: ff.label,
                    defaultValue: ff.defaultValue,
                    placeholder: ff.placeholder,
                    required: ff.required,
                    helpText: ff.helpText,
                    type: ff.type as EContentOfferTemplateBlockFormFieldType,
                  })) || [],
                type: cb.type as EContentOfferTemplateBlockType,
              })),
              name: values.name,
              title: values.title || null,
              filename: values.filename || null,
              isPublished: !!values.isPublished,
              includeEmptyLineItems: !!values.includeEmptyLineItems,
              marginTop: values.pageOptions.marginTop,
              marginBottom: values.pageOptions.marginBottom,
              marginLeft: values.pageOptions.marginLeft,
              marginRight: values.pageOptions.marginRight,
              cssStyles: values.pageOptions.cssStyles || null,
              headerTemplate: values.pageOptions.headerTemplate || null,
              footerTemplate: values.pageOptions.footerTemplate || null,
              type: values.type,
            },
          },
          update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(),
        });
        reset(toFormSchema((res.data!.createContentOfferTemplate || {}) as ContentOfferTemplateListOutput));
        setCreatedId(res.data!.createContentOfferTemplate.id);
        dispatchMessage(dispatch, i18next.t('offertemplate-created'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  watch(() => {
    setRenderCycle(renderCycle + 1);
  });

  const _reassignSequence = (contentBlocks: ContentBlockFormType[]) => {
    contentBlocks.forEach((cb, index) => {
      cb.sequence = index + 1;
    });
    contentBlocksReplace(contentBlocks);
  };

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('offertemplates-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <FormProvider {...form}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <Grid item xs={12}>
            <CustomTabs
              headers={[i18next.t('offertemplate-data-tab'), i18next.t('offertemplate-template-tab')]}
              tabs={[
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormInputText name="name" control={control} label={i18next.t('offertemplate-name')} required disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SpaceSelectionInput
                      checkAdmin
                      name="spaceId"
                      control={control}
                      disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                   <FormInputDropdown
                      name='type'
                      control={control}
                      label={i18next.t('offertemplate-type')}
                      required
                      options={Object.values(EContentOfferTemplateType).map((v) => ({ value: v, label: i18next.t(`enums-EContentOfferTemplateType-${v}`) }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputCheckbox
                      name="isPublished"
                      control={control}
                      label={i18next.t('offertemplate-ispublished')}
                      required
                      disabled={!canEdit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputCheckbox
                      name="includeEmptyLineItems"
                      control={control}
                      label={i18next.t('offertemplate-includeemptylineitems')}
                      required
                      disabled={!canEdit}
                    />
                  </Grid>
                </Grid>,

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <HTMLOfferTemplateRenderer
                      key={`HTMLRenderer_${renderCycle}`}
                      spaceId={props.data.space.id}
                      pageOptions={watch('pageOptions')}
                      contentBlocks={watch('contentBlocks')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-document')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText name="title" control={control} label={i18next.t('offertemplate-title')} disabled={!canEdit} />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText name="filename" control={control} label={i18next.t('offertemplate-filename')} disabled={!canEdit} />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-header')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.headerTemplate"
                                label={i18next.t('offertemplate-header')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!canEdit}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.footerTemplate"
                                label={i18next.t('offertemplate-footer')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!canEdit}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-page')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginTop"
                                label={i18next.t('offertemplate-marginTop')}
                                control={control}
                                required
                                disabled={!canEdit}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginBottom"
                                label={i18next.t('offertemplate-marginBottom')}
                                control={control}
                                required
                                disabled={!canEdit}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginLeft"
                                label={i18next.t('offertemplate-marginLeft')}
                                control={control}
                                required
                                disabled={!canEdit}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginRight"
                                label={i18next.t('offertemplate-marginRight')}
                                control={control}
                                required
                                disabled={!canEdit}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-css')}`}>
                          <FormInputText
                            name="pageOptions.cssStyles"
                            label={i18next.t('offertemplate-css')}
                            textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }}
                            control={control}
                            disabled={!canEdit}
                          />
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      {canEdit && getValues('contentBlocks').length > 0 && (
                        <Grid item xs={12}>
                          <Select
                            value={contentBlockAdd.value}
                            onChange={e => {
                              setContentBlockAdd({
                                value: e.target.value,
                                position: 'start',
                              });
                            }}
                          >
                            <MenuItem value="NULL" selected>
                              <em>{i18next.t('offerblock-contentblock-add')}</em>
                            </MenuItem>
                            <MenuItem value="CUSTOM_FORM">{i18next.t('offerblock-customform-add')}</MenuItem>
                            <MenuItem value="PLAIN_HTML">{i18next.t('offerblock-plainhtml-add')}</MenuItem>
                            <MenuItem value="INPUT_DAYS">{i18next.t('offerblock-inputdays-add')}</MenuItem>
                            <MenuItem value="LINE_ITEMS">{i18next.t('offerblock-lineitems-add')}</MenuItem>
                          </Select>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination) {
                              const contentBlocks = getValues('contentBlocks');
                              const [reorderedItem] = contentBlocks.splice(result.source.index, 1);
                              contentBlocks.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequence(contentBlocks);
                            }
                          }}
                        >
                          <Droppable droppableId="characters">
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className="characters">
                                {contentBlocksFields.map((cb, index) => (
                                  <Draggable key={`${cb.id}_s`} draggableId={`${index}_s`} index={index} isDragDisabled={!canEdit}>
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <EditorAccordionDummy
                                          cssStyles={watch('pageOptions.cssStyles') || ''}
                                          contentBlock={cb}
                                          index={index}
                                          control={control}
                                          onDelete={index => {
                                            contentBlocksRemove(index);
                                            _reassignSequence(getValues('contentBlocks'));
                                          }}
                                          disabled={!canEdit}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                      {canEdit && (
                        <Grid item xs={12}>
                          <Select
                            value={contentBlockAdd.value}
                            onChange={e => {
                              setContentBlockAdd({
                                value: e.target.value,
                                position: 'end',
                              });
                            }}
                          >
                            <MenuItem value="NULL" selected>
                              <em>{i18next.t('offerblock-contentblock-add')}</em>
                            </MenuItem>
                            <MenuItem value="CUSTOM_FORM">{i18next.t('offerblock-customform-add')}</MenuItem>
                            <MenuItem value="PLAIN_HTML">{i18next.t('offerblock-plainhtml-add')}</MenuItem>
                            <MenuItem value="INPUT_DAYS">{i18next.t('offerblock-inputdays-add')}</MenuItem>
                            <MenuItem value="LINE_ITEMS">{i18next.t('offerblock-lineitems-add')}</MenuItem>
                          </Select>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>,
              ]}
            />
          </Grid>
          {canEdit && (
            <Grid item xs={12}>
              {showValidationAlert(validationErrors)}
            </Grid>
          )}
          <Grid item xs={12}>
            {canEdit && (
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
                onClick={async () => {
                  const valid = await trigger();
                  if (valid) {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                {i18next.t('offertemplate-save')}
              </Button>
            )}
            {canEdit && props.data.id > 0 && <OfferTemplateDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
            {props.data.id > 0 && <OfferTemplateCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
            {props.data.id > 0 && (
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                color="secondary"
                startIcon={exportLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
                disabled={exportLoading}
                onClick={async () => {
                  try {
                    const res = await exportMutateFunction({
                      variables: {
                        id: props.data.id,
                      },
                    });
                    if (res.data?.exportOfferTemplates) {
                      exportToFile(res.data?.exportOfferTemplates, 'application/json', 'seminargo-offertemplate.json');
                    }
                  } catch (err) {
                    dispatchException(dispatch, err);
                  }
                }}
              >
                {i18next.t('offertemplates-export-run')}
              </Button>
            )}
          </Grid>
        </FormProvider>
      </Grid>
    </>
  );
}

export default function OfferTemplate(props: OfferTemplateProps) {
  const contentQuery = useQuery(OFFERTEMPLATE_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading = contentQuery.loading;
  const error = contentQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />
  else return <OfferTemplateForm data={contentQuery.data!.viewOfferTemplate as ContentOfferTemplateListOutput} />;
}

export function OfferTemplateCreate(props: OfferTemplateCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;
  return (
    <OfferTemplateForm
      data={
        {
          id: -1,
          isPublished: false,
          includeEmptyLineItems: false,
          contentBlocks: [],
          name: 'Preisübersicht',
          space: { id: initialSpaceId(user, filter) } as any,
        } as any
      }
    />
  );
}

interface OfferTemplateCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function OfferTemplateCopyButton(props: OfferTemplateCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isAdmin && user.space;

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_CONTENTOFFERTEMPLATE_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id },
        update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('offertemplate-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;

  if (props.icon) {
    return (
      <IconButton disabled={!canEdit || copyMutateLoading} onClick={__do}>
        <Tooltip title={i18next.t('offertemplate-copy')}>
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    );
  } else {
    return (
      <Button
        sx={{ marginRight: 2 }}
        variant="contained"
        color="secondary"
        disabled={!canEdit || copyMutateLoading}
        startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
        onClick={__do}
      >
        {i18next.t('offertemplate-copy')}
      </Button>
    );
  }
}

interface OfferTemplateDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function OfferTemplateDeleteButton(props: OfferTemplateDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_CONTENTOFFERTEMPLATE_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('offertemplate-confirm-delete')}
      confirmationTitle={i18next.t('offertemplate-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_OFFERTEMPLATES_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_OFFERTEMPLATES_QUERIES(),
          });
          navigate('/content/templates/offertemplates');
          dispatchMessage(dispatch, i18next.t('offertemplate-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('offertemplate-delete')}
    </ConfirmationButton>
  );
}
