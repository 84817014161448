import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, CircularProgress, Chip } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';

import SimpleTable from 'components/table/SimpleTable';
import { SPACES_LIST_QUERY } from '../gql';
import { ListSpacesQuery } from '__generated__/graphql';

import { unicodeIndent } from 'components/security/SpaceSelectionInput';
import { ErrorAlert } from 'pages/error';
import { CountryName } from 'components/Language';

type TListSpacesElement = ListSpacesQuery['listSpaces'][0];

export default function SpacesList() {
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(SPACES_LIST_QUERY);

  const spaces = useMemo(() => {
    const spaces = [] as TListSpacesElement[];

    const _addSpace = (space: TListSpacesElement, level: number = 0) => {
      spaces.push({
        ...space,
        name: `${unicodeIndent(level)} ${space.name}`,
      });
      if (space.children && space.children.length > 0) {
        const children = (data?.listSpaces || []).filter(fs => space.children.findIndex(cc => fs.id == cc.id) >= 0);
        for (const child of children) {
          _addSpace(child, level + 1);
        }
      }
    };
    const rootSpaces = (data?.listSpaces || []).filter(s => !s.parent);
    for (const rootSpace of rootSpaces) {
      _addSpace(rootSpace, 0);
    }

    return spaces;
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{i18next.t('spaces-list-page-title')}</title>
      </Helmet>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert err={error} />}
      {!loading && !error && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  i18next.t('spaces-list-header-name'),
                  i18next.t('spaces-list-header-country'),
                  i18next.t('spaces-list-header-users'),
                  i18next.t('spaces-list-header-license'),
                ]}
                rows={spaces.map(row => [
                  <Link to={`/settings/security/spaces/${row.id}`}>{row.name}</Link>,
                  <CountryName country={row.country} />,
                  [
                    ...row.users.map((u, i) => {
                      const isAdmin = row.members.find(m => m.user.id === u.id && m.isAdmin);
                      return (
                        <Chip
                          key={i}
                          icon={isAdmin ? <SupervisorAccountIcon /> : <PersonIcon />}
                          label={u.name}
                          onClick={() => navigate(`/settings/security/users/${u.id}`)}
                        />
                      );
                    }),
                    ...row.members
                      .filter(m => row.users.findIndex(u => u.id === m.user.id) < 0)
                      .map((m, i) => {
                        return (
                          <Chip
                            key={row.users.length + i}
                            icon={m.isAdmin ? <SupervisorAccountIcon /> : <PersonIcon />}
                            label={m.user.name}
                            onClick={() => navigate(`/settings/security/users/${m.user.id}`)}
                          />
                        );
                      }),
                  ],
                  row.activeSEMPlan ? row.activeSEMPlan.semPlan.label : null,
                ])}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/settings/security/spaces/_create')}
              >
                {i18next.t('spaces-list-add')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
