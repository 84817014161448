import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import yup from 'validation';
import i18next from 'i18next';
import moment from 'moment';

import { Checkbox, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';

import { FormInputCountryDropdown, FormInputDropdown } from 'components/form/FormInputDropdown';
import { FormInputText } from 'components/form/FormInputText';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { FormInputDate } from 'components/form/FormInputDate';
import { FormInputNumber, InputNumberTextField } from 'components/form/FormInputNumber';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import SimpleAccordion from 'components/SimpleAccordion';
import SimpleTable from 'components/table/SimpleTable';
import { PreviewHTMLRenderer } from 'components/template_editor/HTMLRenderer';
import { formatDate } from 'components/DateTime';
import { extractClientErrors } from 'helper/apollo';
import { showValidationAlert } from 'helper/form';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { filterSelector } from 'helper/filter';

import { HOTEL_LIST_DROPDOWN_QUERY } from 'pages/settings/gql';
import { PRODUCTS_LIST_QUERY, SERVICETYPES_LIST_QUERY, FACILITIES_LIST_QUERY } from 'pages/products/gql';
import { EVICT_OFFER_QUERIES, OFFER_CREATE_MUTATION, OFFER_PREVIEW_HTML_QUERY, QUICKPRICE_DEHYDRATE_QUERY } from './gql';

import {
  EFacilityPayment,
  EProductPayment,
  EOfferRoomOccupancy,
  FacilityListOutput,
  HotelListOutput,
  ProductListOutput,
  EOfferRoomSeating,
  ECountryCode,
  ELanguageCode,
} from '__generated__/graphql';
import { QuickPriceCalculatorContext } from 'components/template_editor/context';
import { QuickPriceCalculator } from 'semshared/pricelist/quickprice';

const validationSchema = yup.object().shape({
  hotelId: yup.number().required().label(i18next.t('offer-create-hotel')),
  input: yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    serviceTypeSku: yup.string().required().label(i18next.t('offer-create-service-type')),
    comment: yup.string(),
    days: yup
      .array()
      .required()
      .of(
        yup.object().shape({
          day: yup.number().required(),
          occupancy: yup.array().required().of(yup.mixed<EOfferRoomOccupancy>().required()),
          seating: yup.array().required().of(yup.mixed<EOfferRoomSeating>().required()),
          addonProducts: yup.array().of(
            yup.object().shape({
              sku: yup.string().required(),
              count: yup.number().required().label(i18next.t('offer-create-input-days-product-count')),
              lookupCode: yup.string(),
            }),
          ),
          addonFacilities: yup.array().of(
            yup.object().shape({
              sku: yup.string().required(),
              count: yup.number().required().label(i18next.t('offer-create-input-days-facility-count')),
              lookupCode: yup.string(),
            }),
          ),
          overnightGuests: yup.number().required(),
          dayGuests: yup.number().required(),
        }),
      ),
    language: yup.mixed<ELanguageCode>().required().oneOf(Object.values(ELanguageCode)),
    prevdayGuests: yup.number().required(),
  }),
  client: yup.object().shape({
    firstname: yup.string().required().label(i18next.t('offer-create-client-firstname')),
    lastname: yup.string().required().label(i18next.t('offer-create-client-lastname')),
    company: yup.string().required().label(i18next.t('offer-create-client-company')),
    email: yup.string().required().email().label(i18next.t('offer-create-client-email')),
    address: yup.string().required().label(i18next.t('offer-create-client-address')),
    city: yup.string().required().label(i18next.t('offer-create-client-city')),
    zip: yup.string().required().label(i18next.t('offer-create-client-zip')),
    country: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('offer-create-client-country')),
    phone: yup.string().required().label(i18next.t('offer-create-client-phone')),
    billingCompany: yup.string().required(),
    billingFirstname: yup.string().required(),
    billingLastname: yup.string().required(),
    billingAddress: yup.string().required(),
    billingCity: yup.string().required(),
    billingZip: yup.string().required(),
    billingCountry: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required(),
  }),
});

interface OfferFormCreateProps {
  hotels: HotelListOutput[];
  products: ProductListOutput[];
  facilities: FacilityListOutput[];
}

function OfferFormCreate(props: OfferFormCreateProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [roomsCounts, setRoomsCounts] = useState<number[]>([1]);
  const [htmlOffer, setHtmlOffer] = useState<string>('');
  const [htmlLoading, setHtmlLoading] = useState<boolean>(true);
  const [htmlTimeout, setHtmlTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [htmlError, setHtmlError] = useState<string[] | null>(null);

  const [createOfferMutation] = useMutation(OFFER_CREATE_MUTATION);

  const [quickPriceCalculator, setQuickPriceCalculator] = useState<any>(new QuickPriceCalculator());

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/offers/${createdId}#editor`);
  }, [createdId]);

  type OfferFormType = yup.InferType<typeof validationSchema>;
  const _emptyForm = (): OfferFormType => ({
    hotelId: props.hotels.length > 0 ? props.hotels[0].id : -1,
    input: {
      startDate: moment().toDate(),
      endDate: moment().add(1, 'days').toDate(),
      serviceTypeSku: props.hotels.length > 0 && props.hotels[0].serviceTypes.length > 0 ? props.hotels[0].serviceTypes[0].serviceType.sku : '',
      comment: '',
      days: [],
      language: ELanguageCode.de,
      prevdayGuests: 0,
    },
    client: {
      firstname: '',
      lastname: '',
      email: '',
      company: '',
      address: '',
      city: '',
      zip: '',
      country: ECountryCode.AT,
      phone: '',
      billingCompany: '',
      billingFirstname: '',
      billingLastname: '',
      billingAddress: '',
      billingCity: '',
      billingZip: '',
      billingCountry: ECountryCode.AT,
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    setValue,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: _emptyForm(),
  });

  const hotelId = watch('hotelId');
  const startDate = watch('input.startDate');
  const endDate = watch('input.endDate');
  const inputDays = watch('input.days');

  const _toVariablesSchema = () => {
    const hotelId = watch('hotelId');
    const client = watch('client');
    const input = watch('input');

    return {
      hotelId: hotelId,
      client: client,
      input: {
        ...input,
        days: input.days.map(day => ({
          ..._.omit(day, 'dayGuests'),
          totalGuests: day.overnightGuests + day.dayGuests,
        })),
      },
    };
  };

  useEffect(() => {
    setValue('client.billingAddress', watch('client.address'));
    trigger();
  }, [watch('client.address')]);

  useEffect(() => {
    setValue('client.billingLastname', watch('client.lastname'));
    trigger();
  }, [watch('client.lastname')]);

  useEffect(() => {
    setValue('client.billingFirstname', watch('client.firstname'));
    trigger();
  }, [watch('client.firstname')]);

  useEffect(() => {
    setValue('client.billingAddress', watch('client.address'));
    trigger();
  }, [watch('client.address')]);

  useEffect(() => {
    setValue('client.billingCity', watch('client.city'));
    trigger();
  }, [watch('client.city')]);

  useEffect(() => {
    setValue('client.billingZip', watch('client.zip'));
    trigger();
  }, [watch('client.zip')]);

  useEffect(() => {
    setValue('client.billingCountry', watch('client.country'));
    trigger();
  }, [watch('client.country')]);

  useEffect(() => {
    setValue('client.billingCompany', watch('client.company'));
    trigger();
  }, [watch('client.company')]);

  const [quickPriceDehydrateQuery] = useLazyQuery(QUICKPRICE_DEHYDRATE_QUERY);

  useEffect(() => {
    const hotel = props.hotels.find(h => h.id === hotelId);
    if (hotel && hotel.serviceTypes.length > 0) {
      setValue('input.serviceTypeSku', hotel.serviceTypes[0].serviceType.sku);
    } else {
      setValue('input.serviceTypeSku', '');
    }
    trigger();
    const res = quickPriceDehydrateQuery({
      variables: {
        hotelId,
      },
      fetchPolicy: 'network-only',
    }).then(p => {
      if (p.error) {
        dispatchException(dispatch, p.error);
      } else {
        if (p.data?.quickPriceDehydrate) {
          quickPriceCalculator?.hydrate(p.data?.quickPriceDehydrate);
        }
      }
    });
  }, [hotelId]);

  useEffect(() => {
    if (moment(endDate).isBefore(moment(startDate))) {
      setValue('input.endDate', startDate);
    }
    trigger();
  }, [startDate]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (moment(endDate).isBefore(moment(startDate))) return;
    if (moment(endDate).isAfter(moment(startDate)) || moment(endDate).isSame(moment(startDate))) {
      const daysCount = moment(endDate).diff(moment(startDate), 'days') + 1;
      setRoomsCounts(_.range(daysCount).map(day => roomsCounts[day] || 1));
      setValue(
        'input.days',
        [...Array(daysCount).keys()].map((day, dayIndex) => ({
          day: day,
          occupancy: inputDays[dayIndex] ? inputDays[dayIndex].occupancy : Array(roomsCounts[dayIndex]).fill(EOfferRoomOccupancy.FULLDAY),
          seating: inputDays[dayIndex] ? inputDays[dayIndex].seating : Array(roomsCounts[dayIndex]).fill(EOfferRoomSeating.UFORM),
          addonProducts: inputDays[dayIndex] ? inputDays[dayIndex].addonProducts : [],
          addonFacilities: inputDays[dayIndex] ? inputDays[dayIndex].addonFacilities : [],
          overnightGuests: inputDays[dayIndex] ? inputDays[dayIndex].overnightGuests : 0,
          dayGuests: inputDays[dayIndex] ? inputDays[dayIndex].dayGuests : 0,
        })),
      );
    }
    trigger();
  }, [startDate, endDate]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: OfferFormType) => {
    try {
      const res = await createOfferMutation({
        variables: _toVariablesSchema(),
        update: cache => EVICT_OFFER_QUERIES(cache),
        awaitRefetchQueries: true,
      });
      reset(_emptyForm());
      setCreatedId(res.data!.createOffer.id);
      dispatchMessage(dispatch, i18next.t('offernew-status-created'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const [loadHtml] = useLazyQuery(OFFER_PREVIEW_HTML_QUERY, { fetchPolicy: 'network-only' });

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name && (name === 'hotelId' || name.startsWith('input.') || name.startsWith('client.'))) {
        if (htmlTimeout) clearTimeout(htmlTimeout);
        setHtmlLoading(true);
        setHtmlTimeout(
          setTimeout(() => {
            loadHtml({
              variables: _toVariablesSchema(),
            }).then(p => {
              if (p.error) {
                setHtmlError(extractClientErrors(p.error));
              } else {
                setHtmlError(null);
                setHtmlOffer(p.data?.offerQuickInputHtmlPreview?.html || '');
              }
              setHtmlLoading(false);
            });
            setHtmlTimeout(null);
          }, 1000),
        );
      }
    });
  }, [watch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{i18next.t('offer-create-header')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SimpleAccordion header={<b>{`${i18next.t('offer-create-step1-header')}`}</b>} autoOpen={props.hotels?.length === 1 ? false : true}>
              <FormInputDropdown
                name="hotelId"
                control={control}
                label={i18next.t('offer-create-hotel')}
                options={props.hotels?.map(hotel => ({ value: hotel.id, label: hotel.name })) || []}
                required
              />
            </SimpleAccordion>
          </Grid>
          <Grid item xs={12}>
            <SimpleAccordion header={<b>{`${i18next.t('offer-create-step2-header')}`}</b>} autoOpen={true}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormInputText name="client.firstname" control={control} label={i18next.t('offer-create-client-firstname')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.lastname" control={control} label={i18next.t('offer-create-client-lastname')} required />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.email" control={control} label={i18next.t('offer-create-client-email')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.phone" control={control} label={i18next.t('offer-create-client-phone')} required />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.company" control={control} label={i18next.t('offer-create-client-company')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.address" control={control} label={i18next.t('offer-create-client-address')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.city" control={control} label={i18next.t('offer-create-client-city')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText name="client.zip" control={control} label={i18next.t('offer-create-client-zip')} required />
                </Grid>
                <Grid item xs={4}>
                  <FormInputCountryDropdown name="client.country" control={control} label={i18next.t('offer-create-client-country')} required />
                </Grid>
              </Grid>
            </SimpleAccordion>
          </Grid>
          <Grid item xs={12}>
            <SimpleAccordion header={<b>{`${i18next.t('offer-create-step3-header')}`}</b>} autoOpen={true}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormInputDate
                    name="input.startDate"
                    control={control}
                    label={i18next.t('offer-create-input-startDate')}
                    datePickerProps={{
                      disablePast: true,
                      shouldDisableDate: (date: any) => {
                        return !quickPriceCalculator.priceListSelector?.inValidRange(date.toDate());
                      },
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputDate
                    name="input.endDate"
                    control={control}
                    label={i18next.t('offer-create-input-endDate')}
                    datePickerProps={{
                      disablePast: true,
                      shouldDisableDate: (date: any) => {
                        return !quickPriceCalculator.priceListSelector?.inValidRange(date.toDate());
                      },
                    }}
                    required
                  />
                </Grid>
                {quickPriceCalculator.priceListSelector?.getValidityRanges().length > 0 && (
                  <Grid item xs={12}>
                    Nur Daten mit gültig hinterlegter Preisliste können ausgewählt werden.
                  </Grid>
                )}
              </Grid>
            </SimpleAccordion>
          </Grid>
          <Grid item xs={12}>
            <SimpleAccordion header={<b>{`${i18next.t('offer-create-step4-header')}`}</b>} autoOpen={true}>
              <FormInputDropdown
                name="input.serviceTypeSku"
                control={control}
                label={i18next.t('offer-create-service-type')}
                options={
                  (props.hotels.find(h => h.id === hotelId) || { serviceTypes: [] }).serviceTypes.map(serviceType => ({
                    value: serviceType.serviceType.sku,
                    label: serviceType.serviceType.name,
                  })) || []
                }
                required
              />
            </SimpleAccordion>
          </Grid>
          <Grid item xs={12}>
            <SimpleAccordion header={<b>{`${i18next.t('offer-create-step5-header')}`}</b>} autoOpen={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleAccordion header={<b>{i18next.t('offer-create-preday-header')}</b>} autoOpen={true}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormInputNumber
                          name="input.prevdayGuests"
                          control={control}
                          label={i18next.t('offer-create-input-prevdayGuests')}
                          required
                        />
                      </Grid>
                    </Grid>
                  </SimpleAccordion>
                </Grid>
                {inputDays.map((day: any, dayIndex: number) => (
                  <Grid item xs={12} key={`day_${dayIndex}`}>
                    <SimpleAccordion
                      header={
                        <b>
                          {i18next.t('offer-create-day-header')} {dayIndex + 1} ({formatDate(moment(startDate).add(dayIndex, 'days').toDate())})
                        </b>
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SimpleAccordion
                            header={`Gäste (${inputDays[dayIndex].overnightGuests} Übernachtungsgäste / ${inputDays[dayIndex].dayGuests} Tagesgäste)`}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <FormInputNumber
                                  name={`input.days.${dayIndex}.overnightGuests`}
                                  control={control}
                                  label={i18next.t('offer-create-input-days-overnightGuests')}
                                  required
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormInputNumber
                                  name={`input.days.${dayIndex}.dayGuests`}
                                  control={control}
                                  label={i18next.t('offer-create-input-days-dayGuests')}
                                  required
                                />
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleAccordion header={`Räume: ${roomsCounts[dayIndex]}`}>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <InputNumberTextField
                                  value={(roomsCounts[dayIndex] || 1).toString()}
                                  onChange={(event: any) => {
                                    const { value } = event.target;
                                    const newRoomsCounts = roomsCounts.map((_, rcIndex) => (rcIndex === dayIndex ? value : roomsCounts[rcIndex]));
                                    setRoomsCounts(newRoomsCounts);
                                    setValue(
                                      `input.days.${dayIndex}.occupancy`,
                                      _.range(newRoomsCounts[dayIndex]).map(
                                        roomIndex => inputDays[dayIndex].occupancy?.[roomIndex] || EOfferRoomOccupancy.FULLDAY,
                                      ),
                                    );
                                    setValue(
                                      `input.days.${dayIndex}.seating`,
                                      _.range(newRoomsCounts[dayIndex]).map(
                                        roomIndex => inputDays[dayIndex].seating?.[roomIndex] || EOfferRoomSeating.UFORM,
                                      ),
                                    );
                                  }}
                                  label={i18next.t('offer-create-input-days-occupancy-roomcount')}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <SimpleTable
                                      headers={['', i18next.t('offer-create-input-days-occupancy'), i18next.t('offer-create-input-days-seating')]}
                                      rows={_.range(roomsCounts[dayIndex]).map(roomIndex => [
                                        <>{`Raum ${roomIndex + 1}`}</>,
                                        <FormInputDropdown
                                          name={`input.days.${dayIndex}.occupancy.${roomIndex}`}
                                          control={control}
                                          label={i18next.t('offer-create-input-days-occupancy')}
                                          options={Object.keys(EOfferRoomOccupancy).map(key => ({
                                            value: key,
                                            label: i18next.t(`enums-EOfferRoomOccupancy-${key}`),
                                          }))}
                                          required
                                        />,
                                        <FormInputDropdown
                                          name={`input.days.${dayIndex}.seating.${roomIndex}`}
                                          control={control}
                                          label={i18next.t('offer-create-input-days-seating')}
                                          options={Object.keys(EOfferRoomSeating).map(key => ({
                                            value: key,
                                            label: i18next.t(`enums-EOfferRoomSeating-${key}`),
                                          }))}
                                          required
                                        />,
                                      ])}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleAccordion header={`Ausstattung / Zusatzleistungen:`}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {(props.facilities.filter(facility => facility.isAddon && facility.recurring === EFacilityPayment.BYCOUNT) ||
                                    []) && (
                                    <Grid item xs={12}>
                                      <SimpleTable
                                        headers={[
                                          '',
                                          i18next.t('offer-create-input-days-facility-name'),
                                          i18next.t('offer-create-input-days-facility-sku'),
                                          i18next.t('offer-create-input-days-facility-recurring'),
                                        ]}
                                        rows={props.facilities
                                          .filter(facility => facility.isAddon && facility.recurring === EFacilityPayment.BYCOUNT)
                                          .map(facility => {
                                            const addonFacilityIndex = (inputDays[dayIndex].addonFacilities || []).findIndex(
                                              addonFacility => addonFacility.sku === facility.sku,
                                            );

                                            return [
                                              <Checkbox
                                                color="primary"
                                                checked={addonFacilityIndex >= 0}
                                                onChange={event => {
                                                  const checked = event.target.checked;
                                                  if (checked) {
                                                    if (addonFacilityIndex < 0) {
                                                      setValue(`input.days.${dayIndex}.addonFacilities`, [
                                                        ...(inputDays[dayIndex].addonFacilities || []),
                                                        {
                                                          sku: facility.sku,
                                                          count: 1,
                                                          lookupCode: facility.lookupCode || '',
                                                        },
                                                      ]);
                                                    }
                                                  } else {
                                                    setValue(`input.days.${dayIndex}.addonFacilities`, [
                                                      ...(inputDays[dayIndex].addonFacilities || []).filter(
                                                        addonFacility => addonFacility.sku !== facility.sku,
                                                      ),
                                                    ]);
                                                  }
                                                  trigger();
                                                }}
                                              />,
                                              <strong>{facility.name}</strong>,
                                              <>{facility.sku}</>,
                                              <>
                                                {facility.recurring === EFacilityPayment.BYCOUNT && addonFacilityIndex >= 0 && (
                                                  <FormInputNumber
                                                    name={`input.days.${dayIndex}.addonFacilities.${addonFacilityIndex}.count`}
                                                    control={control}
                                                    label={i18next.t('offer-create-input-days-facility-count')}
                                                  />
                                                )}
                                                {facility.recurring === EFacilityPayment.BYCOUNT &&
                                                  addonFacilityIndex < 0 &&
                                                  i18next.t('offer-create-input-days-facility-count-bycount')}
                                                {facility.recurring === EFacilityPayment.BYDAY &&
                                                  i18next.t('offer-create-input-days-facility-count-byday')}
                                                {facility.recurring === EFacilityPayment.BYGUEST &&
                                                  i18next.t('offer-create-input-days-facility-count-byguest')}
                                              </>,
                                            ];
                                          })}
                                      />
                                    </Grid>
                                  )}
                                  {(props.products.filter(product => product.isAddon) || []).length > 0 && (
                                    <Grid item xs={12}>
                                      <SimpleTable
                                        headers={[
                                          '',
                                          i18next.t('offer-create-input-days-product-name'),
                                          i18next.t('offer-create-input-days-product-sku'),
                                          i18next.t('offer-create-input-days-product-recurring'),
                                        ]}
                                        rows={props.products
                                          .filter(product => product.isAddon)
                                          .map(product => {
                                            const addonProductIndex = (inputDays[dayIndex].addonProducts || []).findIndex(
                                              addonProduct => addonProduct.sku === product.sku,
                                            );

                                            return [
                                              <Checkbox
                                                color="primary"
                                                checked={addonProductIndex >= 0}
                                                onChange={event => {
                                                  const checked = event.target.checked;
                                                  if (checked) {
                                                    if (addonProductIndex < 0) {
                                                      setValue(`input.days.${dayIndex}.addonProducts`, [
                                                        ...(inputDays[dayIndex].addonProducts || []),
                                                        {
                                                          sku: product.sku,
                                                          count: 1,
                                                          lookupCode: product.lookupCode || '',
                                                        },
                                                      ]);
                                                    }
                                                  } else {
                                                    setValue(`input.days.${dayIndex}.addonProducts`, [
                                                      ...(inputDays[dayIndex].addonProducts || []).filter(
                                                        addonProduct => addonProduct.sku !== product.sku,
                                                      ),
                                                    ]);
                                                  }
                                                  trigger();
                                                }}
                                              />,
                                              <strong>{product.name}</strong>,
                                              <>{product.sku}</>,
                                              <>
                                                {product.recurring === EProductPayment.BYCOUNT && addonProductIndex >= 0 && (
                                                  <FormInputNumber
                                                    name={`input.days.${dayIndex}.addonProducts.${addonProductIndex}.count`}
                                                    control={control}
                                                    label={i18next.t('offer-create-input-days-product-count')}
                                                  />
                                                )}
                                                {product.recurring === EProductPayment.BYCOUNT &&
                                                  addonProductIndex < 0 &&
                                                  i18next.t('offer-create-input-days-product-count-bycount')}
                                                {product.recurring === EProductPayment.BYGUESTANDDAY &&
                                                  i18next.t('offer-create-input-days-product-count-byguestandday')}
                                                {product.recurring === EProductPayment.BYGUEST &&
                                                  i18next.t('offer-create-input-days-product-count-byguest')}
                                              </>,
                                            ];
                                          })}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </SimpleAccordion>
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                ))}
              </Grid>
            </SimpleAccordion>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <PreviewHTMLRenderer previewHtml={htmlOffer || ''} previewError={htmlError ? htmlError.join(' ') : null} previewLoading={htmlLoading} />
      </Grid>
      <Grid item xs={12}>
        {showValidationAlert(validationErrors, (err: String) => !err.includes('billing'))}
      </Grid>
      <Grid item xs={12}>
        <ConfirmationButton
          sx={{ marginRight: 2 }}
          variant="contained"
          color="primary"
          confirmationQuestion={i18next.t('offernew-create-text')}
          confirmationTitle={i18next.t('offernew-create')}
          disabled={!_.isEmpty(validationErrors) || !isDirty || isSubmitting || isValidating}
          startIcon={<PlusIcon />}
          onConfirm={async () => {
            const valid = await trigger();
            if (valid) {
              handleSubmit(onSubmit)();
            }
          }}
        >
          {i18next.t('offer-process-create')}
        </ConfirmationButton>
      </Grid>
    </Grid>
  );
}

export function OfferCreate() {
  const filter = filterSelector();

  const hotelsQuery = useQuery(HOTEL_LIST_DROPDOWN_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });
  const facilityQuery = useQuery(FACILITIES_LIST_QUERY, {
    variables: {
      spaceId: (filter && filter.spaceId) || null,
    },
  });

  const hotelsLoading = hotelsQuery.loading;
  const productsLoading = productsQuery.loading;
  const facilityLoading = facilityQuery.loading;

  if (hotelsLoading || productsLoading || facilityLoading) return <CircularProgress />;

  return (
    <OfferFormCreate
      hotels={hotelsQuery.data?.listHotels as HotelListOutput[]}
      products={productsQuery.data?.listProducts as ProductListOutput[]}
      facilities={facilityQuery.data?.listFacilities as FacilityListOutput[]}
    />
  );
}
